import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const PostalCodesPage = lazy(() => import('@/containers/Views/PostalCodes/PostalCodesPage'));

const PostalCodesRouter = (
  <Route path="postal_codes">
    <Route 
      index
      element={(
        <PrivateRoute 
          pageName={EPage.PostalCodes} 
          permissions={EBasicActions.Find}
        >
          <PostalCodesPage />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default PostalCodesRouter;
