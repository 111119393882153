import React, { memo } from 'react';
import { Image } from 'antd';

const Loader = () => {
  const customLoader = process.env.REACT_APP_LOADER;
  
  return (
    <div className="load loaded">
      <div className="load__icon-wrap">
        {
          customLoader
            ? <Image width={120} src={customLoader} />
            : (
              <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            )
        }
      </div>
    </div>
  );
};

export default memo(Loader);
