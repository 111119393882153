import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Form, FormInstance, InputNumber, Row, Segmented,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import formatQty from '@/shared/helpers/formatQty';

interface QtyInputProps {
  form: FormInstance<any>;
  multipliers?: number[];
  initIndex?: number;
  precision?: number;
  stepper?: number;
  min?: number;
  max?: number;
  value: number;
  onChange: (value: number, multiplier: number) => void;
  onIncrease: (stepper: number, multiplier: number) => void;
  onDecrease: (stepper: number, multiplier: number) => void;
  disableIncrease?: boolean;
  disableDecrease?: boolean;
}

const QtyInput = ({
  disableDecrease = false,
  disableIncrease = false,
  form,
  multipliers = [0.01, 0.1, 1, 10, 100],
  precision = 1,
  stepper = 1,
  min = 0,
  max,
  value,
  initIndex,
  onChange,
  onDecrease,
  onIncrease,
}: QtyInputProps) => {
  const [currentMultiplierIndex, setCurrentMultiplierIndex] = useState(initIndex ?? 0);

  const decreaseIsDisabled = disableDecrease || (value <= min);
  const increaseIsDisabled = Boolean(disableIncrease || (max && value >= max));

  const initMultiplier = () => {
    const middleIndex = Math.floor((multipliers.length / 2));

    setCurrentMultiplierIndex(middleIndex);
  };

  useEffect(() => {
    if (!initIndex) {
      initMultiplier();
    }
  }, []);

  return (
    <Row>
      <Col span={24} style={{ width: 'max-content', padding: 10 }}>
        <Row justify="center" style={{ marginBottom: 10 }}>
          <Segmented
            value={multipliers[currentMultiplierIndex]}
            onChange={(v: any) => {
              const currentIndex = multipliers.findIndex(n => n === v);
              setCurrentMultiplierIndex(currentIndex);
            }}
            options={multipliers}
          />
        </Row>
      </Col>
      <Col span={24}>
        <Form
          form={form}
        >
          <Form.Item name="quantity" style={{ marginBottom: 10 }}>
            <Row justify="center">
              <InputNumber
                style={{ height: 40, fontSize: 24, width: '80%' }}
                value={value}
                size="large"
                inputMode="numeric"
                precision={precision}
                onChange={(newValue: number | null) => {
                  if (!newValue) {
                    onChange(0, multipliers[currentMultiplierIndex]);
                    return;
                  }

                  onChange(newValue, multipliers[currentMultiplierIndex]);
                }}
                min={0}
              />
            </Row>
          </Form.Item>
        </Form>
      </Col>
      <Col span={24}>
        <Row justify="space-around" align="middle" style={{ marginBottom: 0 }}>
          <Button
            style={{ height: 50, width: '30%' }}
            icon={<MinusOutlined style={{ height: 20, width: 20, paddingLeft: 0 }} />}
            disabled={decreaseIsDisabled}
            onClick={() => onDecrease(stepper, multipliers[currentMultiplierIndex])}
          >
            {
              formatQty({
                qty: stepper * multipliers[currentMultiplierIndex],
                decimalPoints: precision,
              })
            }
          </Button>
          <Button
            style={{ height: 50, width: '30%' }}
            icon={<PlusOutlined style={{ height: 25, width: 25 }} />}
            disabled={increaseIsDisabled}
            onClick={() => onIncrease(stepper, multipliers[currentMultiplierIndex])}
          >
            {
              formatQty({
                qty: stepper * multipliers[currentMultiplierIndex],
                decimalPoints: precision,
              })
            }
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default QtyInput;
