import React, { createContext, useContext, useState } from 'react';
import { ReactElementLike } from 'prop-types';
import PickingJourneySearchTypes from '@/enums/PickingJourneySearchTypes';
import PickingJourneySteps from '@/enums/PickingJourneySteps';
import PickingTypes from '@/enums/PickingTypes';
import Folio from '@/interfaces/Folio';
import { Product } from '@/interfaces/ProductRefactor';
import Rack from '@/interfaces/Rack';

interface IPickingJourney {
  rack: Rack | null,
  product: Product | null,
  referenceQty: number,
  foliosReferenceQty: number,
  productQty: number,
  foliosList: Folio[],

  pickingType: PickingTypes,
  searchType: PickingJourneySearchTypes,
  currentStep: PickingJourneySteps,
  isModalVisible: boolean;

  // Setters
  setProduct: (p: Product) => void,
  setRack: (r: Rack) => void,
  // evaluateNextStep: (
  //   currStep: PickingJourneySteps, currProduct: Product|null, currRack: Rack|null
  //   )=>PickingJourneySteps,
  selectPickingType: (newPickingType: PickingTypes) => void,
  nextStep: (currStep?: PickingJourneySteps) => void,
  prevStep: (currStep?: PickingJourneySteps, currentProduct?: Product | null, currentRack?: Rack | null) => void,
  selectSearchType: (newSearchtype: PickingJourneySearchTypes) => void,
  setFoliosReferenceQty: (newFoliosRefQty: number) => void,
  setProductQty: (newProductQty: number) => void,
  setReferenceQty: (newRefQty: number) => void,
  setFoliosList: React.Dispatch<React.SetStateAction<Folio[]>>,
  setCurrentStep: (newCurrStep: PickingJourneySteps) => void,

  resetValues: () => void,
  toggleModal: (isVisible?: boolean) => boolean;
}

interface IChildren {
  children: ReactElementLike;
}


const usePickingJourney = (): IPickingJourney => {
  const [rack, setRack] = useState<IPickingJourney['rack']>(null);
  const [product, setProduct] = useState<IPickingJourney['product']>(null);
  const [pickingType, setPickingType] = useState<IPickingJourney['pickingType']>(PickingTypes.Simple);
  const [searchType, setSearchType] = useState<IPickingJourney['searchType']>(PickingJourneySearchTypes.Folio);
  const [currentStep, setCurrentStep] = useState<IPickingJourney['currentStep']>(PickingJourneySteps.Rack);
  const [foliosReferenceQty, setFoliosReferenceQty] = useState<IPickingJourney['foliosReferenceQty']>(0);
  const [productQty, setProductQty] = useState<IPickingJourney['productQty']>(0);
  const [referenceQty, setReferenceQty] = useState<IPickingJourney['referenceQty']>(0);
  const [foliosList, setFoliosList] = useState<IPickingJourney['foliosList']>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const nextStep = (
    currStep: PickingJourneySteps = currentStep,
  ): PickingJourneySteps => {
    if (!rack) {
      setCurrentStep(PickingJourneySteps.Rack);
      return PickingJourneySteps.Rack;
    }
    // Rack Step
    if (currStep === PickingJourneySteps.Rack) {
      if (!product) {
        setCurrentStep(PickingJourneySteps.Product);
        return PickingJourneySteps.Product;
      }
      // If there is a product
      setCurrentStep(PickingJourneySteps.Product);
      return PickingJourneySteps.Product;
    }

    // Product Step
    if (!product) {
      setCurrentStep(PickingJourneySteps.Product);
      return PickingJourneySteps.Product;
    }
    if (currentStep === PickingJourneySteps.Product) {
      // If there is a product
      const { useFolio } = product;
      if (pickingType === PickingTypes.Advanced && useFolio && foliosReferenceQty === 0) {
        setCurrentStep(PickingJourneySteps.FoliosQty);
        return PickingJourneySteps.FoliosQty;
      }
      if (pickingType === PickingTypes.Advanced && useFolio && foliosReferenceQty > 0) {
        setCurrentStep(PickingJourneySteps.FoliosList);
        return PickingJourneySteps.FoliosList;
      }
      if (pickingType === PickingTypes.Advanced && !useFolio && foliosReferenceQty === 0) {
        setCurrentStep(PickingJourneySteps.ReferenceQty);
        return PickingJourneySteps.ReferenceQty;
      }
      if (pickingType === PickingTypes.Advanced && !useFolio && foliosReferenceQty > 0) {
        setCurrentStep(PickingJourneySteps.ProductQty);
        return PickingJourneySteps.ProductQty;
      }
      if (pickingType === PickingTypes.Simple && !useFolio) {
        setCurrentStep(PickingJourneySteps.ProductQty);
        return PickingJourneySteps.ProductQty;
      }
    }
    if (currentStep === PickingJourneySteps.ReferenceQty) {
      setCurrentStep(PickingJourneySteps.ProductQty);
      return PickingJourneySteps.ProductQty;
    }
    if (currentStep === PickingJourneySteps.ProductQty) {
      setCurrentStep(PickingJourneySteps.FoliosQty);
      return PickingJourneySteps.FoliosQty;
    }
    if (currentStep === PickingJourneySteps.FoliosQty) {
      setCurrentStep(PickingJourneySteps.FoliosList);
      return PickingJourneySteps.FoliosList;
    }
    return currStep;
  };



  //   const evaluateNextStep = (
  //     currStep: PickingJourneySteps,
  //     currProduct: Product| null,
  //     currRack: Rack | null,
  // ): PickingJourneySteps => {
  //     if (!currRack) {
  //       // setCurrentStep(PickingJourneySteps.Rack);
  //       return PickingJourneySteps.Rack;
  //     }
  //     // // Rack Step
  //     // if (currStep === PickingJourneySteps.Rack) {
  //     //   if (!currProduct) {
  //     //     // setCurrentStep(PickingJourneySteps.Product);
  //     //     return PickingJourneySteps.Product;
  //     //   }
  //     //   // If there is a product
  //     //   // setCurrentStep(PickingJourneySteps.Product);
  //     //   return PickingJourneySteps.Product;
  //     // }

  //         // Product Step
  //         if (!currProduct) {
  //           // setCurrentStep(PickingJourneySteps.Product);
  //           return PickingJourneySteps.Product;
  //         }
  //         // if (currentStep === PickingJourneySteps.Product) {
  //         //   // If there is a product
  //         //   const { useFolio } = product;
  //         //   if (pickingType === PickingTypes.Advanced && useFolio && foliosReferenceQty === 0) {
  //         //     setCurrentStep(PickingJourneySteps.FoliosQty);
  //         //     return PickingJourneySteps.FoliosQty;
  //         //   }
  //         //   if (pickingType === PickingTypes.Advanced && useFolio && foliosReferenceQty > 0) {
  //         //     setCurrentStep(PickingJourneySteps.FoliosList);
  //         //     return PickingJourneySteps.FoliosList;
  //         //   }
  //         //   if (pickingType === PickingTypes.Advanced && !useFolio && foliosReferenceQty === 0) {
  //         //     setCurrentStep(PickingJourneySteps.ReferenceQty);
  //         //     return PickingJourneySteps.ReferenceQty;
  //         //   }
  //         //   if (pickingType === PickingTypes.Advanced && !useFolio && foliosReferenceQty > 0) {
  //         //     setCurrentStep(PickingJourneySteps.ProductQty);
  //         //     return PickingJourneySteps.ProductQty;
  //         //   }
  //         //   if (pickingType === PickingTypes.Simple && !useFolio) {
  //         //     setCurrentStep(PickingJourneySteps.ProductQty);
  //         //     return PickingJourneySteps.ProductQty;
  //         //   }
  //         // }
  //         // if (currentStep === PickingJourneySteps.ReferenceQty) {
  //         //   setCurrentStep(PickingJourneySteps.ProductQty);
  //         //   return PickingJourneySteps.ProductQty;
  //         // }
  //         // if (currentStep === PickingJourneySteps.ProductQty) {
  //         //   setCurrentStep(PickingJourneySteps.FoliosQty);
  //         //   return PickingJourneySteps.FoliosQty;
  //         // }
  //         // if (currentStep === PickingJourneySteps.FoliosQty) {
  //         //   setCurrentStep(PickingJourneySteps.FoliosList);
  //         //   return PickingJourneySteps.FoliosList;
  //         // }
  //     return currStep;
  //   };




  const prevStep = (
    currStep: PickingJourneySteps = currentStep,
    currentProduct: Product | null = product,
  ): PickingJourneySteps => {
    if (currStep === PickingJourneySteps.Product) {
      setCurrentStep(PickingJourneySteps.Rack);
      return PickingJourneySteps.Rack;
    }
    if (currStep === PickingJourneySteps.ReferenceQty) {
      setCurrentStep(PickingJourneySteps.Product);
      return PickingJourneySteps.Product;
    }
    if (currStep === PickingJourneySteps.FoliosQty) {
      setCurrentStep(PickingJourneySteps.Product);
      return PickingJourneySteps.Product;
    }
    if (currStep === PickingJourneySteps.FoliosList) {
      setCurrentStep(PickingJourneySteps.FoliosQty);
      return PickingJourneySteps.FoliosQty;
    }
    if (currStep === PickingJourneySteps.ProductQty && !currentProduct?.useFolio
      && pickingType === PickingTypes.Simple) {
      setCurrentStep(PickingJourneySteps.Product);
      return PickingJourneySteps.Product;
    }
    if (currStep === PickingJourneySteps.ProductQty) {
      setCurrentStep(PickingJourneySteps.ReferenceQty);
      return PickingJourneySteps.ReferenceQty;
    }
    return currStep;
  };

  const toggleModal = (isVisible?: boolean) => {
    if (isVisible === undefined) {
      const newValue = !isModalVisible;
      setIsModalVisible(newValue);
      return newValue;
    }
    setIsModalVisible(isVisible);
    return isVisible;
  };

  const resetValues = () => {
    setRack(null);
    setProduct(null);
    setPickingType(PickingTypes.Simple);
    setSearchType(PickingJourneySearchTypes.Folio);
    setCurrentStep(PickingJourneySteps.Rack);
    setFoliosReferenceQty(0);
    setProductQty(0);
    setReferenceQty(0);
    setFoliosList([]);
  };


  return {
    rack,
    product,
    pickingType,
    searchType,
    currentStep,
    foliosList,
    foliosReferenceQty,
    productQty,
    referenceQty,
    nextStep,
    prevStep,
    setProduct,
    setRack,
    toggleModal,
    selectPickingType: setPickingType,
    selectSearchType: setSearchType,
    setCurrentStep,
    setFoliosReferenceQty,
    setProductQty,
    setReferenceQty,
    setFoliosList,
    resetValues,
    // evaluateNextStep,
    isModalVisible,
  };
};

const PickingJourneyContext = createContext<IPickingJourney>({
  rack: null,
  product: null,
  referenceQty: 0,
  foliosReferenceQty: 0,
  productQty: 0,
  foliosList: [],
  isModalVisible: false,

  pickingType: PickingTypes.Simple,
  searchType: PickingJourneySearchTypes.Folio,
  currentStep: PickingJourneySteps.Rack,
  setProduct: () => { },
  setRack: () => { },
  nextStep: () => { },
  prevStep: () => { },
  selectPickingType: () => { },
  selectSearchType: () => { },
  setFoliosReferenceQty: () => { },
  setProductQty: () => { },
  setReferenceQty: () => { },
  setFoliosList: () => { },
  setCurrentStep: () => { },
  // evaluateNextStep: (currStep, currProduct, currRack) => PickingJourneySteps.Rack,

  resetValues: () => { },
  toggleModal: () => false,
});

export const PickingJourneyProvider = ({ children }: IChildren) => {
  const pickingJourneyState = usePickingJourney();

  return (
    <PickingJourneyContext.Provider value={pickingJourneyState}>
      {children}
    </PickingJourneyContext.Provider>
  );
};

export default function PickingJourneyConsumer() {
  return useContext(PickingJourneyContext);
}
