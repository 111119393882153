import axiosApiConfig from '@/axiosApiConfig';
import Folio from '@/interfaces/Folio';
import LPN from '@/interfaces/LPN';
import { Product } from '@/interfaces/ProductRefactor';

export async function getSearchInventory(data:{search: string, rackId: string, type: string}): Promise<any> {
  const result : any = await axiosApiConfig.get('/search_inventory', { params: { ...data } });
  return result.data;
}

export async function getSearchFolioInventory(data:{search: string, rackId: string}): Promise<Product> {
    const result : any = await axiosApiConfig.get('/search_inventory', { params: { ...data, type: 'folio' } });
    return result.data;
}

export async function getSearchLPNInventory(data:{search: string, rackId: string}): Promise<LPN> {
  const result : any = await axiosApiConfig.get('/search_inventory', { params: { ...data, type: 'lpn' } });
  return result.data;
}

export async function getSearchSKUInventory(data:{search: string, rackId: string}): Promise<Product> {
  const result : any = await axiosApiConfig.get('/search_inventory', { params: { ...data, type: 'sku' } });
  return result.data;
}


export default getSearchFolioInventory;
