import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const ProvidersPage = lazy(() => import('@/containers/Views/Provider/ProvidersPage'));
const ProviderNew = lazy(() => import('@/containers/Views/Provider/ProviderForm/ProviderNew'));
const ProviderProductManager = lazy(() => import('@/containers/Views/Provider/ProviderProductManager'));
const ProviderEdit = lazy(() => import('@/containers/Views/Provider/ProviderForm/ProviderEdit'));
const ProviderDetails = lazy(() => import('@/containers/Views/Provider/ProviderDetails'));

const ProviderRouter = (
  <Route path="providers">
    <Route
      index
      element={(
        <PrivateRoute 
          pageName={EPage.Providers} 
          permissions={EBasicActions.Find}
        >
          <ProvidersPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Providers} 
          permissions={EBasicActions.Create}
        >
          <ProviderNew />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/product_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.ProviderProducts} 
          permissions={[EBasicActions.Create, EBasicActions.Delete]}
        >
          <ProviderProductManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Providers} 
          permissions={EBasicActions.Get}
        >
          <ProviderEdit />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id"
      element={(
        <PrivateRoute 
          pageName={EPage.Providers} 
          permissions={EBasicActions.Edit}
        >
          <ProviderDetails />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default ProviderRouter;
