import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const WarehousesPage = lazy(() => import('@/containers/Views/Warehouse/WarehousesPage'));
const WarehouseDetails = lazy(() => import('@/containers/Views/Warehouse/WarehouseDetails/index'));
const WarehouseEditForm = lazy(() => import('@/containers/Views/Warehouse/WarehouseForm/warehouseEdit'));
const ShippingTypeProductsManager = lazy(() => import('@/containers/Views/Warehouse/ShippingTypeProductsManager'));
const DeliveryPointProductsManager = lazy(() => import('@/containers/Views/Warehouse/DeliveryPointProductsManager'));
const DeliveryPointMixesManager = lazy(() => import('@/containers/Views/Warehouse/DeliveryPointMixesManager'));
const DeliveryPointsManger = lazy(() => import('@/containers/Views/DeliveryPoint/DeliveryPointsManger'));
const ZonePCManager = lazy(() => import('@/containers/Views/Warehouse/ZonePCManager'));

const WarehouseRouter = (
  <Route path="warehouses">
    <Route 
      index
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses} 
          permissions={EBasicActions.Find}
        >
          <WarehousesPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses} 
          permissions={EBasicActions.Edit}
        >
          <WarehouseEditForm />
        </PrivateRoute>
      )}
    />
    <Route
      path=":warehouseId/shipping_types/:shippingTypeId/products"
      element={(
        <PrivateRoute 
          pageName={EPage.ShippingTypes}
          permissions={EBasicActions.Edit}
        >
          <ShippingTypeProductsManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":warehouseId/delivery_points/:deliveryPointId/products"
      element={(
        <PrivateRoute 
          pageName={EPage.DeliveryPoints}
          permissions={EBasicActions.Edit}
        >
          <DeliveryPointProductsManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":warehouseId/delivery_points/:deliveryPointId/mixes"
      element={(
        <PrivateRoute 
          pageName={EPage.DeliveryPoints}
          permissions={EBasicActions.Edit}
        >
          <DeliveryPointMixesManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id/delivery_points_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.DeliveryPoints}
          permissions={EBasicActions.Edit}
        >
          <DeliveryPointsManger />
        </PrivateRoute>
      )}
    />
    <Route
      path=":warehouseId/zones/:zoneId"
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses}
          permissions={EBasicActions.Edit}
        >
          <ZonePCManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id" 
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses} 
          permissions={EBasicActions.Get}
        >
          <WarehouseDetails />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default WarehouseRouter;
