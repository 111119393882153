import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const DeliveriesPage = lazy(() => import('@/containers/Views/Deliveries/DeliveriesPage'));
const NotFound = lazy(() => import('@/shared/components/NotFound'));
const DeliveryDetailsPage = lazy(() => import('@/containers/Views/Deliveries/DeliveryDetailsPage'));

const DeliveryRouter = (
  <Route path="deliveries">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Deliveries} 
          permissions={EBasicActions.Find}
        >
          <DeliveriesPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="not_found"
      element={(
        <PrivateRoute 
          pageName={EPage.Deliveries} 
          permissions={EBasicActions.Get}
        >
          <NotFound scope="delivery" />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":deliveryIdParam/packing"
      element={(
        <PrivateRoute 
          pageName={EPage.Deliveries} 
          permissions={EBasicActions.Edit}
        >
          <DeliveryDetailsPage />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default DeliveryRouter;
