import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Dropdown, Menu } from 'antd';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import DropdownOption from '@/interfaces/DropdownOption';



interface Props{
  dropdownMenu: DropdownOption[];
} 

const DotsDropdown = ({ dropdownMenu } : Props) => {
  const menu = () => {
    const items: ItemType[] = dropdownMenu.map(dropdownOption => ({
      key: uuidv4(),
      disabled: !!dropdownOption.disabled,
      label: dropdownOption.label,
      danger: dropdownOption.danger,
      onClick: dropdownOption.func,
    }));

    return (
      <Menu items={items} />
    );
  };

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <DotsVerticalIcon style={{ cursor: 'pointer' }} />
    </Dropdown>
  );
};

export default DotsDropdown;
