import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const QuantifiersPage = lazy(() => import('@/containers/Views/Quantifiers/QuantifiersPage'));

const QuantifiersRouter = (
  <Route path="quantifiers">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Quantifiers} 
          permissions={EBasicActions.Find}
        >
          <QuantifiersPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default QuantifiersRouter;
