import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const UnitPage = lazy(() => import('@/containers/Views/Units/UnitPage'));

const UnitsRouter = (
  <Route path="units">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Unit} 
          permissions={EBasicActions.Find}
        >
          <UnitPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);


export default UnitsRouter;
