import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const EstablishmentsListPage = lazy(
  () => import('@/containers/Views/Establishments/EstablishmentsListPage'),
);
const EstablishmentEditPage = lazy(
  () => import('@/containers/Views/Establishments/EstablishmentEditPage'),
);
const EstablishmentNewPage = lazy(
  () => import('@/containers/Views/Establishments/EstablishmentNewPage'),
);
const EstablishmentStaffManager = lazy(
  () => import('@/containers/Views/Establishments/components/EstablishmentStaffManager'),
);
const EstablishmentWorkerManager = lazy(
  () => import('@/containers/Views/Establishments/components/EstablishmentWorkerManager'),
);
const EstablishmentProductManager = lazy(
  () => import('@/containers/Views/Establishments/components/EstablishmentProductManager'),
);
const EstablishmentMixManager = lazy(
  () => import('@/containers/Views/Establishments/components/EstablishmentMixManager'),
);
const OperativeLocationMixManager = lazy(
  () => import('@/containers/Views/Establishments/components/OperativeLocationMixManager'),
);
const EstablishmentDetails = lazy(
  () => import('@/containers/Views/Establishments/EstablishmentDetails'),
);
const OperativeLocationProductManager = lazy(
  () => import('@/containers/Views/Establishments/components/OperativeLocationProductManager'),
);

const EstablishmentsRouter = (
  <Route path="establishments">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Establishments} 
          permissions={EBasicActions.Find}
        >
          <EstablishmentsListPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Establishments} 
          permissions={EBasicActions.Create}
        >
          <EstablishmentNewPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Establishments} 
          permissions={EBasicActions.Edit}
        >
          <EstablishmentEditPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/staff_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.EstablishmentStaff} 
          permissions={[
            EBasicActions.Create,
            EBasicActions.Get,
            EBasicActions.Delete,
          ]}
        >
          <EstablishmentStaffManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/worker_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.EstablishmentStaff} 
          permissions={[
            EBasicActions.Create,
            EBasicActions.Get,
            EBasicActions.Delete,
          ]}
        >
          <EstablishmentWorkerManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/product_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.EstablishmentProducts} 
          permissions={[
            EBasicActions.Create,
            EBasicActions.Get,
            EBasicActions.Delete,
          ]}
        >
          <EstablishmentProductManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/mix_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.EstablishmentMixes} 
          permissions={[
            EBasicActions.Create,
            EBasicActions.Get,
            EBasicActions.Delete,
          ]}
        >
          <EstablishmentMixManager />
        </PrivateRoute>
      )}
    />
    
    <Route 
      path=":establishmentId/operative_location/:operativeLocationId/product_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.EstablishmentOperativeLocationProducts} 
          permissions={EBasicActions.Get}
        >
          <OperativeLocationProductManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":establishmentId/operative_location/:operativeLocationId/mix_manager"
      element={(
        <PrivateRoute 
          pageName={EPage.EstablishmentOperativeLocationMixes} 
          permissions={EBasicActions.Get}
        >
          <OperativeLocationMixManager />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id"
      element={(
        <PrivateRoute 
          pageName={EPage.Establishments} 
          permissions={EBasicActions.Get}
        >
          <EstablishmentDetails />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default EstablishmentsRouter;
