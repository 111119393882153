import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const OrderDetailsPage = lazy(() => import('@/containers/Views/Orders/OrderDetailsPage'));
const OrderPage = lazy(() => import('@/containers/Views/Orders/OrdersPage'));
const NotFound = lazy(() => import('@/shared/components/NotFound'));

const OrdersRouter = (
  <Route path="orders">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Orders} 
          permissions={EBasicActions.Find}
        >
          <OrderPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="details/:orderId/products"
      element={(
        <PrivateRoute 
          pageName={EPage.Orders} 
          permissions={EBasicActions.Find}
        >
          <OrderDetailsPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="details/not_found"
      element={(
        <PrivateRoute 
          pageName={EPage.Orders} 
          permissions={EBasicActions.Find}
        >
          <NotFound scope="order" />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default OrdersRouter;
