import React from 'react';
import { Button, Result, Typography } from 'antd';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Paragraph, Text } = Typography;

interface NotFoundProps {
  scope: 'product' | 'mix' | 'variant' | 'order' | 'delivery';
}

const NotFound = ({ scope }: NotFoundProps) => {
  const { t } = useCustomTranslation();
  const navigate = useNavigate();

  const data: any = {
    product: {
      title: t('product.not_found'),
      button_text: t('product.new'),
      route: 'products/new',
      current_route: 'products/not_found',
      redirect_route: '/products',
      show_new_button: true,
    },
    variant: {
      title: t('variant.not_found'),
      button_text: t('variant.new'),
      route: 'variants/new',
      current_route: 'variants/not_found',
      redirect_route: '/variants',
      show_new_button: true,
    },
    mix: {
      title: t('mix.not_found'),
      button_text: t('mix.new'),
      route: 'mixes/new',
      current_route: 'mixes/not_found',
      redirect_route: '/mixes',
      show_new_button: true,
    },
    order: {
      title: t('order.not_found'),
      button_text: t('order.new'),
      route: 'orders/details',
      current_route: 'orders/details/not_found',
      redirect_route: '/orders',
      show_new_button: false,
    },
    delivery: {
      title: t('delivery.not_found'),
      // button_text: t('delivery.new'),
      route: 'deliveries/',
      current_route: 'deliveries/not_found',
      redirect_route: '/deliveries',
    },
  };

  return (
    <Result
      status="error"
      title={data[scope].title}
      extra={[
        ...data[scope].show_new_button ? [
          <Button
            type="primary"
            key="console"
            onClick={() => navigate('new')}
          >
            {data[scope].button_text}
          </Button>,
        ] : [],
        <Button key="buy" onClick={() => navigate(data[scope].redirect_route)}>
          {t('g.go_to_index')}
        </Button>,
      ]}
    />
  );
};

export default NotFound;
