import React, { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const OperativesPage = lazy(() => import('@/containers/Views/Operative/OperativesPage'));
const OperativeMixesManager = lazy(() => import('@/containers/Views/Operative/OperativeMixesManager'));
const OperativeProductsManager = lazy(() => import('@/containers/Views/Operative/OperativeProductsManager'));
const OperativesDetails = lazy(() => import('@/containers/Views/Operative/OperativesDetails'));
const PageEditOperative = lazy(() => import('@/containers/Views/Operative/OperativesForm/PageEditOperative'));
const OperativeStaffDetailPage = lazy(() => import('@/containers/Views/Staff/OperativeStaffDetailPage'));
const WarehouseMixesManager = lazy(() => import('@/containers/Views/Warehouse/WarehouseMixesManager'));
const WarehouseProductManager = lazy(() => import('@/containers/Views/Warehouse/WarehouseProductManager'));
const WarehouseNewForm = lazy(() => import('@/containers/Views/Warehouse/WarehouseForm/warehouseNew'));

const OperativeRouter = (
  <Route path="operatives">
    <Route
      index
      element={(
        <PrivateRoute
          pageName={EPage.Operatives}
          permissions={EBasicActions.Find}
        >
          <OperativesPage />
        </PrivateRoute>
      )}
    />
    <Route
      path="edit/:id"
      element={(
        <PrivateRoute
          pageName={EPage.Operatives}
          permissions={EBasicActions.Edit}
        >
          <PageEditOperative />
        </PrivateRoute>
      )}
    />
    <Route
      path=":operativeId/warehouses/:warehouseId/product_manager"
      element={(
        <PrivateRoute
          pageName={EPage.WarehouseProducts}
          permissions={[EBasicActions.Create, EBasicActions.Delete]}
        >
          <WarehouseProductManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":operativeId/warehouses/:warehouseId/mixes_manager"
      element={(
        <PrivateRoute
          pageName={EPage.WarehouseProducts}
          permissions={[EBasicActions.Create, EBasicActions.Delete]}
        >
          <WarehouseMixesManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id/product_manager"
      element={(
        <PrivateRoute
          pageName={EPage.OperativeProducts}
          permissions={[EBasicActions.Create, EBasicActions.Delete]}
        >
          <OperativeProductsManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id/mixes_manager"
      element={(
        <PrivateRoute
          pageName={EPage.OperativeMixes}
          permissions={[EBasicActions.Create, EBasicActions.Delete]}
        >
          <OperativeMixesManager />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id/staff/config/:staffId"
      element={(
        <PrivateRoute
          pageName={EPage.WarehouseStaff}
          permissions={EBasicActions.Create}
        >
          <OperativeStaffDetailPage />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id/warehouses/new"
      element={(
        <PrivateRoute
          pageName={EPage.Warehouses}
          permissions={EBasicActions.Create}
        >
          <WarehouseNewForm />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id"
      element={(
        <PrivateRoute
          pageName={EPage.Operatives}
          permissions={EBasicActions.Get}
        >
          <OperativesDetails />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default OperativeRouter;
