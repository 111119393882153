import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const CategoriesDetails = lazy(() => import('@/containers/Views/Categories/CategoriesDetails'));

const CategoriesRouter = (
  <Route path="categories">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Categories} 
          permissions={EBasicActions.Find}
        >
          <CategoriesDetails />
        </PrivateRoute>
      )} 
    />
  </Route>
);


export default CategoriesRouter;
