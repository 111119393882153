import React, { 
  useState, 
} from 'react';
import { 
  message, 
} from 'antd';

import useCustomTranslation from '@/hooks/useCustomTranslation';

import PickingTypes from '@/enums/PickingTypes';
import PickingJourneySteps from '@/enums/PickingJourneySteps';

import Rack from '@/interfaces/Rack';
import { Product } from '@/interfaces/ProductRefactor';

import useAuth, { IAuth } from '@/hooks/useAuth';
import useModal, { ModalHook } from '@/hooks/useModal';

import Optional from '@/types/Optional';
import getRacks, { getRackById } from '@/services/Racks';
import { notification } from '@/shared/helpers';
import { getWhProductsPaginated } from '@/services/Product';
import createInventoryMovement, { CreateInventoryMovementDTO } from '@/services/ProductInventoryMovements';

interface IAddSimpleFolio { 
  folio: string, 
  productToAdd: Product,
}

export interface IJourney {
  // props
  auth: Optional<IAuth>,
  modal: ModalHook,
  
  // states
  originRack: Optional<Rack>,
  setOriginRack: React.Dispatch<React.SetStateAction<Optional<Rack>>>,
  destRack: Optional<Rack>,
  setDestRack: React.Dispatch<React.SetStateAction<Optional<Rack>>>,
  currentStep: PickingJourneySteps,
  setCurrentStep: React.Dispatch<React.SetStateAction<PickingJourneySteps>>,
  referenceQty: number,
  setReferenceQty: React.Dispatch<React.SetStateAction<number>>,
  productQty: number,
  setProductQty: React.Dispatch<React.SetStateAction<number>>,
  product: Optional<Product>,
  setProduct: React.Dispatch<React.SetStateAction<Optional<Product>>>,
  pickingType: PickingTypes,
  setPickingType: React.Dispatch<React.SetStateAction<PickingTypes>>,
  folios: string[],
  setFolios: React.Dispatch<React.SetStateAction<string[]>>,

  // functions
  onMoveSku: () => Promise<void>
  onMoveFolios: (data?: IAddSimpleFolio) => Promise<void>
  fetchRacks: (value: string) => Promise<Rack[]>;
  onScanRack: (value: string, setRack: React.Dispatch<React.SetStateAction<Optional<Rack>>>) => Promise<void>;
  fetchProducts: (warehouseId: string) => (value: string) => Promise<Product[]>;
  reset: () => void,
}

export const useJourney = (): IJourney => {
  const modal = useModal();
  const auth = useAuth();
  const { t } = useCustomTranslation();

  const [originRack, setOriginRack] = useState<IJourney['originRack']>(null);
  const [destRack, setDestRack] = useState<IJourney['destRack']>(null);
  const [pickingType, setPickingType] = useState<PickingTypes>(PickingTypes.Simple);
  const [product, setProduct] = useState<Optional<Product>>(null);
  const [referenceQty, setReferenceQty] = useState<number>(0);
  const [productQty, setProductQty] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<IJourney['currentStep']>(PickingJourneySteps.Rack);
  const [folios, setFolios] = useState<string[]>([]);

  const fetchRacks = (searchValue: string): Promise<any[]> => getRacks({
    'type[$in][0]': 'cust',
    'type[$in][1]': 'mn',
    'type[$in][2]': 'autoservice',
    ...(searchValue ? { '$or[0][alias][$like]': `%${searchValue}%` } : {}),
  });

  const fetchProducts = (warehouseId: string) => async (value: string) => {
    const products = await getWhProductsPaginated(0, 10, {
      scope: 'warehouses',
      id: warehouseId,
      ...(value ? {
        '$or[0][sku][$like]': `%${value}%`,
        '$or[1][nameEn][$like]': `%${value}%`,
        '$or[2][nameEs][$like]': `%${value}%`,
      } : {}),
    });
    
    return products.data;
  };

  const onScanRack = async (value: string, setRack: React.Dispatch<React.SetStateAction<Optional<Rack>>>) => {
    try {
      const scannedRack: Rack = await getRackById(value);

      if (!scannedRack) {
        notification('warning', t('validation.rack_not_found'));
        setRack(null);
        return;
      }

      setRack(scannedRack);
      setCurrentStep(PickingJourneySteps.Config);
    } catch (e) {
      message.warning({ content: t('validation.rack_not_found'), duration: 2 });
    }
  };

  const reset = () => {
    setOriginRack(null);
    setDestRack(null);
    setPickingType(PickingTypes.Simple);
    setProduct(null);
    setFolios([]);
    setReferenceQty(0);
    setCurrentStep(PickingJourneySteps.Rack);
  }; 

  const onMoveSku = async () => {
    if (!product?.id) throw new Error('product_not_found');
    if (!originRack?.id) throw new Error('origin_rack_not_found');
    if (!destRack?.id) throw new Error('dest_rack_not_found');
    if (!auth?.staff?.id) throw new Error('user_not_found');
    if (productQty <= 0) throw new Error('qty_not_found');

    const userId = auth.staff.id;

    const productToMove: CreateInventoryMovementDTO = {
      senderRackId: originRack?.id,
      destRackId: destRack?.id,
      productId: product?.id,
      qty: productQty,
      userId,
    };

    await createInventoryMovement(productToMove);
  };

  const onMoveFolios = async (data?: IAddSimpleFolio) => {
    if (!data?.productToAdd?.id && !product?.id) throw new Error('product_not_found');
    if (!originRack?.id) throw new Error('origin_rack_not_found');
    if (!destRack?.id) throw new Error('dest_rack_not_found');
    if (!auth?.staff?.id) throw new Error('user_not_found');
    if (!data?.folio && folios.length <= 0) throw new Error('folios_not_found');

    const userId = auth.staff.id;

    const productToMove: CreateInventoryMovementDTO = {
      senderRackId: originRack?.id,
      destRackId: destRack?.id,
      productId: data?.productToAdd?.id ?? product?.id ?? 1,
      qty: data?.folio ? 1 : folios.length,
      userId,
      folios: data?.folio ? [data.folio] : folios,
    };

    await createInventoryMovement(productToMove);
  };

  return {
    auth,
    originRack,
    setOriginRack,
    currentStep,
    setCurrentStep,
    productQty,
    setProductQty,
    referenceQty,
    setReferenceQty,
    modal,
    product,
    setProduct,
    pickingType,
    setPickingType,
    fetchRacks,
    onScanRack,
    fetchProducts,
    reset,
    folios,
    setFolios,
    destRack,
    setDestRack,
    onMoveFolios,
    onMoveSku,
  };
};
