import dayjs from 'dayjs';
import { message } from 'antd';

export const staffSelect = staffList => staffList.map(staff => ({ 
    value: staff.id, 
    label: staff.name,
}));

export const franchiseSelect = (staff) => {
    const franchiseelectArray = [];
    staff.forEach((element) => {
        franchiseelectArray.push({ value: element.id, label: element.alias });
    });

    return franchiseelectArray;
};

export const nameSelect = (list) => {
    if (!list) return null;
    return list.map(item => ({
        value: item.id,
        label: item.name,
    }));
};

export const getBreadcrumbOperative = (operative) => {
    if (operative.alias === '') return [];
    const breadcrumbItems = [
        { 
            label: operative.franchise.master_franchise.name, 
            route: `/master_franchises/${operative.franchise.master_franchise.id}`, 
            isActive: false, 
        },
        { 
            label: operative.franchise.alias, 
            route: `/franchises/${operative.franchiseId}`, 
            isActive: false, 
        },
        { 
            label: operative.alias, 
            route: `/operatives/${operative.id}`, 
            isActive: true, 
        },
    ];

    return breadcrumbItems;
};

export const getBreadcrumbFranchise = (franchise) => {
    if (franchise.alias === '') return [];
    const breadcrumbItems = [
        { 
            label: franchise.master_franchise.name, 
            route: `/master_franchises/${franchise.master_franchise.id}`, 
            isActive: false, 
        },
        { 
            label: franchise.alias, 
            route: `/franchises/${franchise.id}`, 
            isActive: true, 
        },
    ];

    return breadcrumbItems;
};

export const getBreadcrumbProvider = (provider) => {
    const breadcrumbItems = [
        {
            // TODO: fix translation
            label: 'Proveedores',
            route: '/providers',
            isActive: false,
        },
        { 
            label: `${provider.alias}`, 
            route: `/providers/${provider.id}`, 
            isActive: true, 
        },
    ];

    return breadcrumbItems;
};

export const getBreadcrumbMasterFranchise = (masterFranchise) => {
    if (masterFranchise.name === '') return [];
    const breadcrumbItems = [
        { 
            label: `/${masterFranchise.name}`, 
            route: `/master_franchises/${masterFranchise.id}`, 
            isActive: true, 
        },
    ];

    return breadcrumbItems;
};

export const getBreadcrumbWarehouse = (warehouse) => {
    if (warehouse.alias === '') return [];
    const breadcrumbItems = [
        { 
            label: warehouse.operative.franchise.master_franchise.name, 
            route: `/master_franchises/${warehouse.operative.franchise.master_franchise.id}`, 
            isActive: false, 
        },
        { 
            label: warehouse.operative.franchise.alias, 
            route: `/franchises/${warehouse.operative.franchise.id}`, 
            isActive: false, 
        },
        { 
            label: warehouse.operative.alias, 
            route: `/operatives/${warehouse.operative.id}`, 
            isActive: false, 
        },
        { 
            label: warehouse.alias, 
            route: `/warehouses/${warehouse.id}`, 
            isActive: true, 
        },
    ];

    return breadcrumbItems;
};

export const formatMoney = (money, locale = 'es-MX', options = { style: 'currency', currency: 'MXN' }) => {
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(money);
};


export const formatPercentage = (value, locale = 'es-MX', options = { style: 'percent', minimumFractionDigits: 2 }) => {
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
};

export const formatDate = (date, format) => {
    if (format) {
        return dayjs(date).format(format);
    }

    return dayjs(date);
};

export const editRelatedProductList = (relatedProductList, productEdit) => {
    const newRelatedProductList = relatedProductList.map((item) => {
        const { operativeId, productId } = item.config;

        if (operativeId === productEdit.operativeId && productId === productEdit.productId) {
            item.config = productEdit;
        }

        return item;
    });

    return newRelatedProductList;
};

export const notification = (type, msg, key = 'KEY') => {
    switch (type) {
        case 'success':
            message.success({ content: msg, key, duration: 5 });
            break;
        case 'error':
            message.error({ content: msg, key, duration: 5 });
            break;
        case 'loading':
            message.loading({ content: msg, key, duration: 5 });
            break;
        case 'warning':
            message.warning({ content: msg, key, duration: 5 });
            break;
        default:
            message.success({ content: msg, key, duration: 5 });
            break;
    }
};
