import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const RolesPage = lazy(() => import('@/containers/Views/Roles/RolesForm/RolesPage'));
const RolesNewForm = lazy(() => import('@/containers/Views/Roles/RolesForm/roleNew'));
const RolesEditForm = lazy(() => import('@/containers/Views/Roles/RolesForm/roleEdit'));

const RolesRouter = (
  <Route path="roles">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Roles} 
          permissions={EBasicActions.Find}
        >
          <RolesPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Roles} 
          permissions={EBasicActions.Create}
        >
          <RolesNewForm />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Roles} 
          permissions={EBasicActions.Edit}
        >
          <RolesEditForm />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default RolesRouter;
