import React from 'react';

interface IContainer {
  children?: any;
  style?: React.CSSProperties;
}

const Container = ({
  children,
  style,
}: IContainer) => (
  <div className="container" style={style}>
    {children}
  </div>
);

export default Container;
