import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';

interface IDestinationInfo {
  to?: ReactNode;
  subTo?: ReactNode;
  from?: ReactNode;
  subFrom?: ReactNode;
  centerIcon?: ReactNode;
}

const DestinationInfo = ({
  from,
  subFrom,
  centerIcon,
  to,
  subTo,
}: IDestinationInfo) => (
  <Row gutter={8}>
    {
      from && (
        <Col>
          {to}{subFrom}
        </Col>
      )
    }
    {
      centerIcon && (
        <Col>
          {centerIcon}
        </Col>
      )
    }
    {
      centerIcon && (
        <Col>
          {to}{subTo}
        </Col>
      )
    }
  </Row>
);

export default DestinationInfo;
