import React, { useEffect, useState } from 'react';
import { 
  Card, Col, Row, Spin, Typography, 
} from 'antd';
import Container from '@/shared/components/Container';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import { getMasterFranchiseConfigurations } from '@/services/MasterFranchiseConfigurationService';

const HomePage = () => {
  const { t } = useCustomTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [homeImage, setHomeImage] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    getMasterFranchiseConfigurations({
      masterFranchiseId: 1,
    }).then((configurations) => {
      const image = configurations.find(configuration => configuration.key === 'home_image');

      if (!image?.key) return;

      setLoading(false);
      setHomeImage(image.value);
    });
  }, []);

  return (
    <Container>
      <Card style={{ minHeight: '80vh' }}>
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Typography.Title level={1}>
              {t('g.welcome')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col>
                {
                  loading ? (
                    <Spin size="large" spinning />
                  ) : (
                    <img 
                      src={homeImage} 
                      alt={t('g.welcome')} 
                      style={{ width: '30vw' }}
                    />
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default HomePage;
