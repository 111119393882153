import React, { useState } from 'react';

export interface ModalHook {
    visible: boolean;
    isLoading: boolean;
    show: () => void;
    hide: () => void;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useModal = (): ModalHook => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const show = ():void => {
        setVisible(true);
    };

    const hide = ():void => {
        setVisible(false);
    };

    return {
        visible,
        isLoading,
        show,
        hide,
        setLoading,
    };
};

export default useModal;
