import axios from 'axios';
import { notification } from './shared/helpers';

// ref: https://github.com/axios/axios#config-defaults
const instance = axios.create({
  // axios config
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  response => response,
  (error) => {
    switch (error.response.status) {
      case 401:
        console.error(error);
        notification('error', 'Not authorized');
        localStorage.clear();

        if (error.response.data.message === 'Invalid login') return;

        window.location.href = '/login'; 
        break;
      case 403:
        console.error(error);
        notification('error', 'Forbidden');
        break;
      default:
        console.error(error);
        break;
    }

    // eslint-disable-next-line consistent-return
    return error.response;
  },
);

instance.interceptors.request.use((config) => {
  if ('staff' in localStorage) {
    let user = localStorage.getItem('staff');
    user = JSON.parse(user === null ? '' : user);
    config.headers.authorization = `Bearer ${user.accessToken}`;
    config.headers.strategy = 'local';
  }

  return config;
});



export default instance;
