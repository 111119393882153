import {
    Image,
} from 'antd';
import React, { ReactNode } from 'react';
import i18next from 'i18next';
import { Product } from '@/interfaces/ProductRefactor';
import CardInfo from './CardInfo';

interface CardInfoProps {
  product: Pick<Product, 'nameEn' | 'nameEs' | 'productImgs' | 'sku' | 'unit' >;
  qty: string;
  extra?: ReactNode;
}

const ProductCardInfo = ({
    extra,
    product,
    qty,
}: CardInfoProps) => (
  <CardInfo
    img={product?.productImgs ? (
      <Image
        width={90}
        src={product?.productImgs[0]?.url}
      />
    ) : undefined}
    label={(i18next.language === 'en' ? product?.nameEn : product?.nameEs) ?? ''}
    subLabel={`${qty ?? ''} ${qty && product?.unit?.code ? product?.unit?.code : ''}`}
    tags={[product?.sku ?? '']}
    extra={extra}
  />
);

export default ProductCardInfo;

