import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const ClientsListPage = lazy(
  () => import('@/containers/Views/Clients/ClientsListPage'),
);

const ClientsRouter = (
  <Route path="clients">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Establishments} 
          permissions={EBasicActions.Find}
        >
          <ClientsListPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default ClientsRouter;
