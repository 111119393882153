import { feathers } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
// @ts-ignore
import io from 'socket.io-client';

const socketIO = io(process.env.REACT_APP_API_URL || 'http://127.0.0.1:3030');
const socket = feathers();

socket.configure(socketio(socketIO));

socketIO.on('connect', () => {
  console.log('Socket connected');
});

socket.service('users').on('created', (data: any) => {
  console.log('entro', data);
});

export default socket;
