import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const DiscountsPage = lazy(() => import('@/containers/Views/Discounts/DiscountsPage'));
const DiscountNew = lazy(() => import('@/containers/Views/Discounts/components/DiscountNew'));
const DiscountEdit = lazy(() => import('@/containers/Views/Discounts/components/DiscountEdit'));

const DiscountsRouter = (
  <Route path="discounts">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Discounts} 
          permissions={EBasicActions.Find}
        >
          <DiscountsPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Discounts} 
          permissions={EBasicActions.Create}
        >
          <DiscountNew />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Discounts} 
          permissions={EBasicActions.Edit}
        >
          <DiscountEdit />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default DiscountsRouter;
