import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

interface IScrollToTop {
  children: React.ReactElement;
}

const ScrollToTop: FC<IScrollToTop> = ({ children }) => {
  const location = useLocation();
  
  useEffect(() => {
    if (location && location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
};

export default ScrollToTop;
