import React, { useEffect, useState } from 'react';
import { List, Divider, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import Folio from '@/interfaces/Folio';
import FolioListItem from '@/shared/components/FolioListItem';
import { PaginationResponse } from '@/hooks/usePaginationService';
import { Product } from '@/interfaces/ProductRefactor';
import Rack from '@/interfaces/Rack';

export interface IOnGetFolios {
  productId: Product['id'];
  rackId: Rack['id'];
  productInventoryId?: number;
  lpnInventoryId?: number;
  skip: number, 
  limit: number,
}

interface FolioScrollListProps {
  data: PaginationResponse<Folio>;
  service: (data: IOnGetFolios) => Promise<void>;
  params: Omit<IOnGetFolios, 'skip' | 'limit'>,
  reloadService?: boolean,
}

const FolioScrollList = ({
  data = {
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
  },
  service, 
  params, 
  reloadService,
}: FolioScrollListProps) => {
  const { t } = useCustomTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);

  console.log('folios', data);

  const onNextPage = () => {
    setLoading(true);

    service({
      ...params,
      skip: skip + 10,
      limit: 10,
    })
    .finally(() => {
      setLoading(false);
      setSkip(skip + 10);
    });
  };
  
  useEffect(() => {
    setLoading(true);

    service({
      ...params,
      skip: 0,
      limit: 10,
    })
    .finally(() => setLoading(false));
  }, [reloadService]);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: '75vh',
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      {!loading && (
        <InfiniteScroll
          dataLength={data.data.length}
          next={() => onNextPage()}
          hasMore={data.data.length < data.total}
          loader={<Spin style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />}
          endMessage={<Divider plain>{t('hints.no_more_data')}</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            bordered
            dataSource={data.data}
            renderItem={(item: any) => (
              <FolioListItem item={item} />
            )}
          />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default FolioScrollList;
