import mergeDeep from '@/shared/helpers/mergeDeep';

/* eslint-disable camelcase */
import restaurant_es from './es/restaurant.json'; 
import mrcheve_es from './es/mrcheveEs.json'; 
import tiemposJSON from './es/tiempos.json'; 
import jtcarnesJSON from './es/jtcarnes.json'; 
import formeeJSON from './es/formee.json'; 
import pharJSON from './es/phar.json';
import protosaJSON from './es/protosa.json';
import frankOptikJSON from './es/frankOptik.json';

import common_es from './es/common.json'; 
import common_en from './en/common.json';

import errors_es from './es/errors.json';
import errors_en from './en/errors.json';

/**
 * To use an industry language, is required to have a valid path usin a LANGUAGE and an INDUSTRY_LANGUAGE
 * Ej
 * - REACT_APP_LANGUAGE="es"
 * - REACT_APP_INDUSTRY_LANGUAGE="restaurant"
 * Points to { es: { resturant: {...} }}
 */
const restaurantEs = mergeDeep(structuredClone(common_es), structuredClone(restaurant_es));
const mrcheveEs = mergeDeep(structuredClone(common_es), structuredClone(mrcheve_es));
const tiempos = mergeDeep(structuredClone(common_es), structuredClone(tiemposJSON));
const jtcarnes = mergeDeep(structuredClone(common_es), structuredClone(jtcarnesJSON));
const formee = mergeDeep(structuredClone(common_es), structuredClone(formeeJSON));
const pharEs = mergeDeep(structuredClone(common_es), structuredClone(pharJSON));
const protosaEs = mergeDeep(structuredClone(common_es), structuredClone(protosaJSON));
const frankOptikEs = mergeDeep(structuredClone(common_es), structuredClone(frankOptikJSON));

export default {
  en: {
    common: common_en,
    errors: errors_en,
  },
  es: {
    common: common_es,
    errors: errors_es,
    restaurant: restaurantEs,
    mrcheve: mrcheveEs,
    phar: pharEs,
    formee,
    tiempos,
    jtcarnes,
    protosa: protosaEs,
    frankOptik: frankOptikEs,
  },
};
