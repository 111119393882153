import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const CouponsPage = lazy(() => import('@/containers/Views/Coupons/CouponsPage'));
const CouponsNew = lazy(() => import('@/containers/Views/Coupons/components/CouponsNew'));

const CouponsRouter = (
  <Route path="coupons">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Coupons} 
          permissions={EBasicActions.Find}
        >
          <CouponsPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Coupons} 
          permissions={EBasicActions.Create}
        >
          <CouponsNew />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default CouponsRouter;
