import React, { lazy } from 'react';
import { PrivateRoute } from '@/hooks/useAuth';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const InventoryEntry = lazy(() => import('@/containers/Views/Inventory/components/InventoryEntry'));
const InventoryPage = lazy(() => import('@/containers/Views/Inventory/InventoryPage'));
const IventoryWriteOff = lazy(() => import('@/containers/Views/Inventory/InventoryWriteOffPage'));

const InventoryRouter = (
  <Route path="inventory">
    <Route 
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.InventoryMovements} 
          permissions={EBasicActions.Find}
        >
          <InventoryPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="entry" 
      element={(
        <PrivateRoute 
          pageName={EPage.InventoryMovements} 
          permissions={EBasicActions.Find}
        >
          <InventoryEntry />
        </PrivateRoute>
      )}
    />
    <Route 
      path="write_off" 
      element={(
        <PrivateRoute 
          pageName={EPage.InventoryMovements} 
          permissions={EBasicActions.Find}
        >
          <IventoryWriteOff />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default InventoryRouter;
