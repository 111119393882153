import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const BannersPage = lazy(() => import('@/containers/Views/Banners/BannersPage'));

const BannerRouter = (
  <Route path="banners">
    <Route
      index
      element={(
        <PrivateRoute 
          pageName={EPage.Banners} 
          permissions={EBasicActions.Find}
        >
          <BannersPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);


export default BannerRouter;
