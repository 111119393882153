import axiosApiConfig from '@/axiosApiConfig';
import Banner from '@/interfaces/Banner';
import Folio from '@/interfaces/Folio';
import LPN from '@/interfaces/LPN';
import { Product } from '@/interfaces/ProductRefactor';
import Rack from '@/interfaces/Rack';
import Staff from '@/interfaces/Staff';

export interface CreateInventoryMovementDTO {
    destRackId: Rack['id'],
    senderRackId: Rack['id'],
    productId: Product['id'],
    userId: Staff['id'],
    qty: number,
    folios?: Folio['id'][]
}
export async function createInventoryMovement(params: CreateInventoryMovementDTO): Promise<any[]> {
    const result : any = await axiosApiConfig.get('/product_inventory_movements', { params: { ...params } });
    return result.data;
}

export async function getLPNInventoryMovements(params?: {
    destRackId: Rack['id'] | string,
    senderRackId: Rack['id'] | string,
    userId: any,
    lpnId: LPN['id']
}): Promise<any[]> {
    const result : any = await axiosApiConfig.get('/product_inventory_movements', { params: { ...params } });
    return result.data;
}

export default createInventoryMovement;
