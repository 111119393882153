import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const useCustomTranslation = (): { t: TFunction } => {
  const industryLang = process.env.REACT_APP_INDUSTRY_LANGUAGE || 'common';

  const { t } = useTranslation(industryLang);

  return { t };
};

export default useCustomTranslation;
