import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useHomeLocation = () => {
  const { pathname } = useLocation();
  const Item = 'location';

  const saveLocation = (location: string) => {
    localStorage.setItem(Item, location);
  };

  useEffect(() => {
    saveLocation(pathname);
  }, [pathname]);
};

export default useHomeLocation;
