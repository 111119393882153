import { Dayjs } from 'dayjs';
import Warehouse from '@/interfaces/Warehouse';
import MetaProp from './MetaProps';

export enum RackType {
    Main = 'mn',
    Custom = 'cust',
    Temporal = 'temp',
    Packing = 'pack',
    Personal = 'perso',
    Autoservice = 'autoservice',
} 

interface Rack {
    id: string; // should be a string r-id-form-1234
    alias: string;
    type: RackType;
    forSale: boolean;
    warehouseId: Warehouse['id'];
    order: number;
    meta: MetaProp;
    tags: string[];
    createdAt: Dayjs;
    updatedAt: Dayjs;
}

export default Rack;
