import React from 'react';
import { Row, Col } from 'antd';
import dayjs from 'dayjs';
import Folio from '@/interfaces/Folio';
import ActiveTag from './ActiveTag';

interface FolioListItemProps {
    item: Folio;
}

const FolioListItem = ({ item }:FolioListItemProps) => (
  <Row style={{
        width: '100%', padding: 10, backgroundColor: 'white', marginTop: 2, marginBottom: 2,
      }}
  >
    <Row
      style={{ width: '100%' }}
    >
      <Col span={8} md={8}>
        <span 
          style={{ fontWeight: 600, display: 'flex', justifyContent: 'center' }}
        >
          {item.id}
        </span>
      </Col>
      <Col span={8} md={8}>
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'end' }}>
          <ActiveTag isActive={item.isActive} />
        </div>
      </Col>
      <Col span={8} md={8}>
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'end' }}>
          {dayjs(item.expirationDate).format('L LTS')}
        </div>
      </Col>
    </Row>
  </Row>
);

export default FolioListItem;
