import axiosApiConfig from '@/axiosApiConfig';
import IMasterFranchiseConfiguration from '@/interfaces/IMasterFranchiseConfiguration';
import MasterFranchise from '@/interfaces/MasterFranchise';

export async function getMasterFranchiseConfigurations({
  masterFranchiseId,
}: { masterFranchiseId : MasterFranchise['id'] }): Promise<IMasterFranchiseConfiguration[]> {
  const result = await axiosApiConfig.get<IMasterFranchiseConfiguration[]>('/master_franchise_configurations', {
    params: { 
      masterFranchiseId,
    },
  });

  return result.data;
}

interface ICreateMasterFranchiseConfiguration {
  value: string;
  masterFranchiseId: number;
  masterFranchiseConfigurationPropId: number;
}

export async function editMasterFranchiseConfigurations(
  data: ICreateMasterFranchiseConfiguration,
): Promise<IMasterFranchiseConfiguration[]> {
  const result = await axiosApiConfig.post<IMasterFranchiseConfiguration[]>('/master_franchise_configurations', data);

  return result.data;
}
