import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const DeliveryPointsPage = lazy(() => import('@/containers/Views/DeliveryPoint/DeliveryPointsPage'));
const DeliveryPointNew = lazy(() => import('@/containers/Views/DeliveryPoint/DeliveryPointForm/DeliveryPointNew'));
const DeliveryPointEdit = lazy(() => import('@/containers/Views/DeliveryPoint/DeliveryPointForm/DeliveryPointEdit'));

const DeliveryPointRouter = (
  <Route path="delivery_points">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.DeliveryPoints} 
          permissions={EBasicActions.Find}
        >
          <DeliveryPointsPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.DeliveryPoints} 
          permissions={EBasicActions.Create}
        >
          <DeliveryPointNew />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.DeliveryPoints} 
          permissions={EBasicActions.Edit}
        >
          <DeliveryPointEdit />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default DeliveryPointRouter;
