import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const BadgesPage = lazy(() => import('@/containers/Views/Badges/BadgesPage'));

const BadgesRouter = (
  <Route path="badges">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Badges} 
          permissions={EBasicActions.Find}
        >
          <BadgesPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default BadgesRouter;
