import React, { FC } from 'react';
import {
  Space,
  Typography,
  List,
} from 'antd';

export interface INotificationItem {
  id: number;
  title: string;
  desc: string;
  onClick?: () => void;
  link?: string;
}

const NotificationListItem: FC<INotificationItem> = ({
  id,
  title,
  desc,
  link,
  onClick,
}) => (
  <List.Item key={id} onClick={onClick}>
    <Space direction="vertical" size="small">
      <Typography.Link href={link}>{title}</Typography.Link>
      <Typography.Text type="secondary">{desc}</Typography.Text>
    </Space>
  </List.Item>
);

export default NotificationListItem;
