/* eslint-disable no-undef */
import axiosApiConfig from '@/axiosApiConfig';
import LPNStatus from '@/enums/LPNStatus';
import { PaginationResponse } from '@/hooks/usePaginationService';
import { EnterLpnInventoryProps } from '@/interfaces/EnterLpnInventory';
import Folio from '@/interfaces/Folio';
import LPN from '@/interfaces/LPN';
import { Product } from '@/interfaces/ProductRefactor';
import Rack from '@/interfaces/Rack';
import Staff from '@/interfaces/Staff';
// import { PaginationProps, PaginationResponse } from '@/hooks/usePaginationService';
import SupplyOrder from '@/interfaces/SupplyOrder';
import Optional from '@/types/Optional';

interface LPNProductsToEnter {
    product: Product;
    qty?: number;
    folios?: Folio[];
}

export async function getLPNs(params?: any): Promise<LPN[]> {
    const result: any = await axiosApiConfig.get('/lpns', { params: { ...params } });

    const returnValue = result.data;

    returnValue.data = result.data.data.map((lpn:any) => ({
        id: lpn.id,
    state: lpn.status,
    products: lpn.lpn_inventories,
    rackId: lpn.rackId,
    }));

    return returnValue;

    // return result.data;
}


export async function getLPNsPaginated(
    skip: number, 
    limit: number, 
    params?: any,
): Promise<PaginationResponse<LPN>> {
    const result: any = await axiosApiConfig.get('/lpns', {
        params: {
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });
    
    const returnValue = result.data;

    returnValue.data = result.data.data.map((lpn:any) => ({
        id: lpn.id,
    state: lpn.status,
    products: lpn.lpn_inventories,
    }));

    return returnValue;
    // return result.data;
}


export async function createLPN(data?: {
    isBroken?: boolean,
    rackId?: string,
    status?: LPNStatus
}): Promise<LPN> {
    // create an LPN, with opened status, to relate to
    const result: any = await axiosApiConfig.post('/lpns', data);
    console.log('created lpn: ', result);
    return result.data;
}

interface CloseLpn {
    lpnId: LPN['id'];
    rackId: Rack['id'];
    closedById: Staff['id'];
    supplyOrderId?: Optional<SupplyOrder['id']>; 
}

export async function closeLPN(data: CloseLpn): Promise<any> {
    // change status to closed
    const result : any = await axiosApiConfig.post('/close_lpn', data);
    return result.data;
}

export async function breakLPN(lpnId: LPN['id'], userId: Staff['id']): Promise<void> {
    const result : any = await axiosApiConfig.post('/break_lpns', {
        lpnId,
        userId,
    });
    return result.data;
}

export async function createLPNSO(data?: any) {
    // create an LPN, with opened status, to relate to
    const result: any = await axiosApiConfig.post('/supply_orders/lpn/entry', data);
    console.log('created lpn: ', result);
    return result.data;
}

export async function createLPNInventoryEntry(data: EnterLpnInventoryProps) {
    // create an LPN, with opened status, to relate to
    const result: any = await axiosApiConfig.post('/inventory/lpn/entry', data);
    console.log('created lpn: ', result);
    return result.data;
}
