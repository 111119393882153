/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Form,
  InputRef,
} from 'antd';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import PickupJourneyQuantityPick from '@/shared/components/forms/pickJourney/PickupJourneyQuantityPick';
import PickupJourneyQuantityReference from '@/shared/components/forms/pickJourney/PickupJourneyQuantityReference';
import PickupJourneyRackInput, { PickupRackInput } from '@/shared/components/forms/pickJourney/PickupJourneyRackInput';
import PickupJourneyFoliosProgress from '@/shared/components/forms/pickJourney/PickupJourneyFoliosProgress';
import PickupJourneyConfig, { 
  PickupJourneyConfigForm,
} from '@/shared/components/forms/pickJourney/PickupJourneyConfig';
import Rack from '@/interfaces/Rack';
import useAuth from '@/hooks/useAuth';
import getSearchFolioInventory, { getSearchSKUInventory } from '@/services/SearchInventories';
import { notification } from '@/shared/helpers';
import PickingJourneySteps from '@/enums/PickingJourneySteps';
import PickingTypes from '@/enums/PickingTypes';
import { Product } from '@/interfaces/ProductRefactor';
import decodeFolioTag from '@/shared/helpers/folios/decodeFolioTag';
import { IJourney } from '@/hooks/useJourney';
import precision from '@/shared/helpers/precision';
import formatQty from '@/shared/helpers/formatQty';

interface IPickUpJourney {
  journey: IJourney;
  reloadData: () => Promise<void>;
}

interface IOnMoveWithoutFoliosParams {
  productId: Product['id'],
  senderRackId: string;
  qty: number,
}

interface IOnMoveFoliosParams {
  productId: Product['id'],
  senderRackId: string;
  folios: string[],
}

const LetDownJourney = ({ 
  journey,
  reloadData, 
}: IPickUpJourney) => {
  const { t } = useCustomTranslation();
  const auth = useAuth();
  const KEY = 'LetDownJourney';

  const [loading, setLoading] = useState<boolean>(false);
  const [rackWarehouseId, setRackWarehouseId] = useState<string>('');

  // Refs
  const qrRackInput = useRef(null);
  const foliosQtyRef = useRef<InputRef>(null);

  // forms
  const [rackInputForm] = Form.useForm<PickupRackInput>();
  const [configInputForm] = Form.useForm<PickupJourneyConfigForm>();
  const [folioQrForm] = Form.useForm<any>();

  const onRackInputSelect = (rack: Rack) => {
    journey.setDestRack(rack);
    setRackWarehouseId(rack.warehouseId.toString());
  };

  const scanSkuOrFolioInput = useRef();
  // const qrFoliosInput = useRef<any>(null);

  const onConfigHandleBack = () => {
    rackInputForm.resetFields();
    journey.setCurrentStep(PickingJourneySteps.Rack);
    journey.reset();
  };

  const onConfigHandleSearch = async (value?: string) => {
    try {
      if (!value) throw new Error('value not found');

      const folioTag = decodeFolioTag(value);
      const rackId = journey.originRack?.id;
  
      if (!rackId) throw new Error('rack not found');

      if (folioTag) {
        const product = await getSearchFolioInventory({ search: folioTag.id, rackId });
        await onConfigHandleSelectFolio(product, folioTag.id);
        return;
      }

      const product = await getSearchSKUInventory({ search: value, rackId });

      if (product.useFolio) {
        await onConfigHandleSelectFolio(product);
        return;
      }
      
      onConfigHandleSelectSku(product);
    } catch (error: any) {
      console.error(error);
      notification('error', t('g.error'), KEY);
    }
  };

  const onConfigHandleNext = async () => {
    try {
      const values = await configInputForm.validateFields();
      const rackId = journey.originRack?.id;
  
      if (!rackId) throw new Error('rack not found');
      // user feedback add notification, please select a rack, and redirect to rack step
      // user feedback add notification, product is not valid in that rack
  
      const product = await getSearchSKUInventory({ search: values.sku, rackId });

      journey.setReferenceQty(0);
      journey.setProductQty(0);

      if (product.useFolio) {
        await onConfigHandleSelectFolio(product);
        return;
      }
  
      onConfigHandleSelectSku(product);
    } catch (error) {
      console.error(error);
      notification('error', t('g.error'), KEY);
    }
  };

  const onConfigHandleSelectSku = (product: Product) => {
    const { pickingType } = journey;
    journey.setProduct(product);
  
    if (pickingType === PickingTypes.Simple) {
      journey.setCurrentStep(PickingJourneySteps.ProductQty);
      return;
    }

    journey.setCurrentStep(PickingJourneySteps.ReferenceQty);
  };

  const onConfigHandleSelectFolio = async (product: Product, folio?: string) => {
    const { pickingType } = journey;
    journey.setProduct(product);
  
    if (pickingType === PickingTypes.Simple) {
      await onSaveSimpleFolio(product, folio);
      return;
    }

    journey.setCurrentStep(PickingJourneySteps.FoliosQty);
  };

  const onSaveSimpleFolio = async (product: Product, folio?: string) => {
    setLoading(true);
    try {
      if (!folio) {
        notification(
          'warning', 
          'Este producto utiliza folios, por favor escaneé un folio o seleccione el tipo avanzado', 
          KEY,
        );
        return;
      }

      await journey.onMoveFolios({ 
        productToAdd: product,
        folio, 
      });

      await reloadData();
      notification('success', t('g.done'), KEY);
      onCloseModal();
    } catch (error) {
      console.error(error);
      notification('error', t('Producto sin inventario'), KEY);
    } finally {
      setLoading(false);
    }
  };

  const onConfigSelectProduct = async (sku: string) => {
    try {
      const rackId = journey.originRack?.id;
    
      if (!rackId) throw new Error('rack not found');

      const product = await getSearchSKUInventory({ search: sku, rackId });
      
      journey.setProduct(product);
    } catch (error: any) {
      console.log('error', error);
      notification('warning', 'Producto sin inventario', KEY);
    }
  };

  const onReferenceQtyHandleBack = () => {
    journey.setCurrentStep(PickingJourneySteps.Config);
  };
  const onReferenceQtyHandleNext = () => {
    try {
      // validate reference amount
      if (journey.referenceQty <= 0) {
        notification('warning', t('validation.please_select_a_product'));
        return;
      }
      journey.setCurrentStep(PickingJourneySteps.ProductQty);
    } catch (e) {
      notification('warning', t('g.sequantity_too_lowlect_a_product'));
    }
  };

  const onProductQtyHandleBack = () => {
    if (journey.pickingType === PickingTypes.Simple) {
      journey.setCurrentStep(PickingJourneySteps.Config);
    } else {
      journey.setCurrentStep(PickingJourneySteps.ReferenceQty);
    }
  };
  
  // handle reference qty
  const onReferenceQtyDecrease = (value: number) => journey.setReferenceQty(
    currVal => formatQty({
      qty: currVal - value,
      decimalPoints: journey.product?.decimalPoints,
    }),
  );

  const onReferenceQtyIncrease = (value: number) => journey.setReferenceQty(
    currVal => formatQty({
      qty: currVal + value,
      decimalPoints: journey.product?.decimalPoints,
    }),
  );

  const onReferenceQtyChange = (value: number) => journey.setReferenceQty(
    formatQty({
      qty: value,
      decimalPoints: journey.product?.decimalPoints,
    }),
  );

  // handle product qty
  const onProductQtyDecrease = (value: number) => journey.setProductQty(
    currVal => formatQty({
      qty: currVal - value,
      decimalPoints: journey.product?.decimalPoints,
    }),
  );

  const onProductQtyIncrease = (value: number) => journey.setProductQty(
    currVal => formatQty({
      qty: currVal + value,
      decimalPoints: journey.product?.decimalPoints,
    }),
  );

  const onProductQtyChange = (value: number) => journey.setProductQty(
    formatQty({
      qty: value,
      decimalPoints: journey.product?.decimalPoints,
    }),
  );

  const onFoliosQtyHandleBack = () => {
    configInputForm.resetFields();
    journey.setPickingType(PickingTypes.Simple);
    journey.setProduct(null);
    journey.setCurrentStep(PickingJourneySteps.Config);
  };

  const onAddFoliosHandleScan = async (newFolio: string) => {
    setLoading(true);
    try {
      const rackId = journey.originRack?.id;
      
      if (!rackId) throw new Error('rack not found');
      if (!journey?.product?.id) throw new Error('product not found');

      const folioTag = decodeFolioTag(newFolio);
      
      if (!folioTag) {
        notification('error', 'El folio no es valido', KEY);
        return;
      }

      const product = await getSearchFolioInventory({ search: folioTag.id, rackId });

      if (String(product.id) !== String(journey.product.id)) {
        notification('error', 'El folio no corresponde con el producto', KEY);
        return;
      }

      journey.setFolios(prevValue => [...prevValue, folioTag.id]);
    } catch (error) {
      console.error(error);
      notification('error', 'El folio no es valido', KEY);
    } finally {
      setLoading(false);
    }
  };

  const onAddWithoutFoliosHandleSubmit = async () => {
    try {
      setLoading(true);
      
      await journey.onMoveSku();

      await reloadData();
      notification('success', t('g.done'), KEY);
      onCloseModal();
    } catch (error) {
      console.error(error);
      notification('error', t('g.error'), KEY);
    } finally {
      setLoading(false);
    }
  };

  const onAddFoliosHandleSubmit = async () => {
    try {
      setLoading(true);

      await journey.onMoveFolios();

      await reloadData();
      notification('success', t('g.done'), KEY);
      onCloseModal();
    } catch (error) {
      console.error(error);
      notification('error', t('g.error'), KEY);
    } finally {
      setLoading(false);
    }
  };

  const onAddFoliosHandleBack = () => {
    journey.setFolios([]);
    journey.setReferenceQty(0);
    journey.setCurrentStep(PickingJourneySteps.FoliosQty);
  };

  const formatQtyFeedback = (currentQty: number, referenceQty: number = 0): string => {
    if (referenceQty === 0) {
      return currentQty.toString();
    }
  
    return `${currentQty} / ${referenceQty}`;
  };

  let productStepper: number = 0;
  let productPrecision: number = 0;

  if (journey.product) {
    if (journey.product.useFolio) {
      productStepper = 1;
      productPrecision = 0;
    } else if (journey.product.stepper) {
      productStepper = journey.product.stepper ?? 0;
      productPrecision = journey.product.decimalPoints ?? 1;
    }
  }

  const onCloseModal = () => {
    journey.modal.hide();
    rackInputForm.resetFields();
    configInputForm.resetFields();
    folioQrForm.resetFields();
    journey.reset();
  };

  useEffect(() => {
    if (!auth?.rack) return;
    journey.setOriginRack(auth?.rack);
  }, [journey.modal.visible]);

  return (
    <Modal
      open={journey.modal.visible}
      onCancel={onCloseModal}
      maskClosable={false}
      footer={null}
      destroyOnClose
    >
      <Row>
        <Col span={24}>
          {
            journey.currentStep === PickingJourneySteps.Rack && (
              <PickupJourneyRackInput
                title={t('g.to_which_rack_will_you_send_products?')}
                form={rackInputForm}
                qrRef={qrRackInput}
                rack={journey.destRack}
                onSelect={onRackInputSelect}
                fetchRacks={journey.fetchRacks}
                onClear={() => journey.setDestRack(null)}
                onSearchRack={(rackId: string) => journey.onScanRack(rackId, journey.setDestRack)}
                disableNext={!journey.destRack}
                onNext={() => {
                  if (!journey.destRack) {
                    notification('warning', t('g.select_a_rack'));
                    return;
                  }
                  journey.setCurrentStep(PickingJourneySteps.Config);
                }}
              />
            )
          }
          {
            journey.currentStep === PickingJourneySteps.Config && (
              <PickupJourneyConfig
                form={configInputForm}
                qrRef={scanSkuOrFolioInput}
                fetchProducts={journey.fetchProducts(rackWarehouseId)}
                onPressEnter={value => onConfigHandleSearch(value)}
                disableNext={!journey.destRack || !journey.product}
                onSelect={onConfigSelectProduct}
                onDeselect={() => journey.setProduct(null)}
                onPickingTypeChange={journey.setPickingType}
                onNext={onConfigHandleNext}
                onBack={onConfigHandleBack}
              />
            )
          }
          {
            journey.currentStep === PickingJourneySteps.ReferenceQty && (
              <PickupJourneyQuantityReference
                product={journey.product}
                rack={journey.destRack}
                quantity={journey.referenceQty}
                stepper={productStepper}
                precision={productPrecision}
                text={t('g.next')}
                hideRange
                onDecrease={onReferenceQtyDecrease}
                onIncrease={onReferenceQtyIncrease}
                onChange={onReferenceQtyChange}
                onBack={onReferenceQtyHandleBack}
                onNext={onReferenceQtyHandleNext}
                loading={loading}
              />
            )
          }
          {
            journey.currentStep === PickingJourneySteps.ProductQty && (
              <PickupJourneyQuantityPick
                hideRange={!journey.referenceQty}
                product={journey.product}
                rack={journey.destRack}
                quantity={journey.productQty}
                referenceQty={journey.referenceQty}
                qtyFeedback={formatQtyFeedback(journey.productQty, journey.referenceQty)}
                stepper={productStepper}
                precision={productPrecision}
                onDecrease={onProductQtyDecrease}
                onIncrease={onProductQtyIncrease}
                onChange={onProductQtyChange}
                text={t('g.finish')}
                onBack={onProductQtyHandleBack}
                onNext={onAddWithoutFoliosHandleSubmit}
                loading={loading}
              />
            )
          }
          {
            journey.currentStep === PickingJourneySteps.FoliosQty && (
              <PickupJourneyQuantityReference
                product={journey.product}
                rack={journey.destRack}
                quantity={journey.referenceQty}
                stepper={1}
                text={t('g.next')}
                hideRange
                disableNext={journey.referenceQty === 0}
                onDecrease={onReferenceQtyDecrease}
                onIncrease={onReferenceQtyIncrease}
                onChange={onReferenceQtyChange}
                onBack={onFoliosQtyHandleBack}
                onNext={() => journey.setCurrentStep(PickingJourneySteps.FoliosList)}
                loading={loading}
              />
            )
          }
          {journey.currentStep === PickingJourneySteps.FoliosList && (
            <PickupJourneyFoliosProgress
              loading={loading}
              form={folioQrForm}
              ref={foliosQtyRef}
              product={journey.product}
              rack={journey.destRack}
              folios={journey.folios}
              maxFolios={journey.referenceQty}
              onSubmit={onAddFoliosHandleSubmit}
              onScan={onAddFoliosHandleScan}
              onBack={onAddFoliosHandleBack}        
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default LetDownJourney;
