import React, { useState, useEffect, FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import {
  Space,
  Button,
  Drawer,
  Cascader,
  Typography,
  Row,
  Col,
  Divider,
  Badge,
  Popover,
} from 'antd';
import socket from '@/socket';
import useAuth, { groupWarehouses } from '@/hooks/useAuth';
import useModal from '@/hooks/useModal';
import isScreenMobile from '@/shared/helpers/isScreenMobile';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SwapOutlined,
  BellOutlined,
} from '@ant-design/icons';
import useBreakpoints from '@/hooks/useBreakpoint';
import usePaginationService from '@/hooks/usePaginationService';
import ENotificationType from '@/enums/ENotificationType';
import { getNotificationsPaginated } from '@/services/NotificationsService';
import formatOrderId from '@/shared/helpers/Order/formatOrderId';
import formatEntryPlanId from '@/shared/helpers/FormatEntryPlanId';
import formatSolpedId from '@/shared/helpers/formatSolpedId';
import formatSupplyOrderId from '@/shared/helpers/formatSupplyOrderId';
import formatDeliveryId from '@/shared/helpers/Delivery/formatDelivery';
import INotification, { 
  IDeliveryCreatedPayload, 
  IEntryPlanCreatedPayload, 
  IOrderCreatedPayload, 
  ISolpedCreatedPayload, 
  ISupplyOrderCreatedPayload, 
} from '@/interfaces/INotification';
import TopbarProfile from './TopbarProfile';
import MyPersonalRackPicking from './MyPersonalRackPicking';
import NotificationsList from './NotificationsList';
import NotificationListItem, { INotificationItem } from './NotificationListItem';

interface ITopbar {
  collapsed: boolean;
  onClickCollapsed: () => void;
}

interface IContextOption {
  key: string;
  label: string;
  options?: {
    label: string;
    value: string;
  }[];
  value?: string;
}

const Topbar: FC<ITopbar> = ({
  collapsed,
  onClickCollapsed,
}) => {
  const { t } = useCustomTranslation();
  const auth = useAuth();
  const myPersonalRackModal = useModal();
  const navigate = useNavigate();

  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const [warehouseContexText, setWarehouseContexText] = useState(t('g.loading'));

  // calulate warehouses context text
  useEffect(() => {
    if (!auth) return;

    const selectedWhs = auth.selectedWarehouses.length;
    const totalWhs = auth.relatedWarehouses.length;
    // get warehouse text
    let contextWarehouseText = '';
    if (selectedWhs === totalWhs) {
      contextWarehouseText = t('g.all');
    } else {
      contextWarehouseText = `${selectedWhs}/${totalWhs} ${t('g.selected_warehouses').toLocaleLowerCase()}`;
    }
    setWarehouseContexText(contextWarehouseText);
  }, [auth?.relatedWarehouses, auth?.selectedWarehouses]);

  const warehouseContextOptions = Object.values(groupWarehouses(auth?.relatedWarehouses || []))
    .map(operative => ({
      label: operative.alias,
      value: operative.id.toString(),
      children: operative.warehouses.map(warehouse => ({
        label: warehouse.alias,
        value: warehouse.id.toString(),
      })),
    }));

  const handleWarehouseContextChange = (value: any, selectedOptions: any) => {
    const warehouseIds: string[] = [];
    value.forEach((selectedCouple: any) => {
      const [operativeId, warehouseId] = selectedCouple;
      warehouseIds.push(warehouseId);
    });
    auth?.setSelectedWarehouses(warehouseIds);
  };

  const warehouseContextValues: any[] = [];
  auth?.selectedWarehouses.forEach((selectedWhId) => {
    const wh = auth.relatedWarehouses.find(relatedWh => relatedWh.id.toString() === selectedWhId.toString());
    if (wh) {
      warehouseContextValues.push([wh.operativeId.toString(), wh.id.toString()]);
    }
  });

  const selectAll = () => {
    auth?.setSelectedWarehouses(auth.relatedWarehouses.map(wh => wh.id.toString()));
  };
  const deselectAll = () => auth?.setSelectedWarehouses([]);

  const formatNotification = (notification: INotification): INotificationItem => {
    if (notification.type === ENotificationType.OrderCreated) {
      const { payload: { order, link } } = notification as INotification<IOrderCreatedPayload>;
      return {
        id: notification.id,
        title: `${t('g.order')} ${formatOrderId(order.id)}`,
        desc: 'Nuevo registro',
        // onClick: () => navigate(link),
        link,
      };
    }
    if (notification.type === ENotificationType.DeliveryCreated) {
      const { payload: { delivery, link } } = notification as INotification<IDeliveryCreatedPayload>;
      return {
        id: notification.id,
        title: `${t('g.delivery')} ${formatDeliveryId(delivery.id)}`,
        desc: 'Nuevo registro',
        // onClick: () => navigate(link),
        link,
      };
    }
    if (notification.type === ENotificationType.SolpedCreated) {
      const { payload: { solped, link } } = notification as INotification<ISolpedCreatedPayload>;
      return {
        id: notification.id,
        title: `${t('g.solped')} ${formatSolpedId(solped.id)}`,
        desc: 'Nuevo registro',
        // onClick: () => navigate(link),
        link,
      };
    }
    if (notification.type === ENotificationType.SupplyOrderCreated) {
      const { payload: { supplyOrder, link } } = notification as INotification<ISupplyOrderCreatedPayload>;
      return {
        id: notification.id,
        title: `${t('g.supply_order')} ${formatSupplyOrderId(supplyOrder.id)}`,
        desc: 'Nuevo registro',
        // onClick: () => navigate(link),
        link,
      };
    }
    if (notification.type === ENotificationType.EntryPlanCreated) {
      const { payload: { entryPlan, link } } = notification as INotification<IEntryPlanCreatedPayload>;
      return {
        id: notification.id,
        title: `${t('g.entry_plan')} ${formatEntryPlanId(entryPlan.id)}`,
        desc: 'Nuevo registro',
        // onClick: () => navigate(link),
        link,
      };
    }
    console.error('could not format notification', notification);
    return {
      id: notification.id,
      title: notification.type,
      desc: JSON.stringify(notification.payload),
    };
  };

  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const notificationsState = usePaginationService({
    fetchPage: getNotificationsPaginated,
    currentPage: 1,
    pageSize: 10,
    params: {
      '$sort[createdAt]': -1,
    },
  });

  useEffect(() => {
    const newNotifications = notificationsState.data;
    // console.log('data change', newNotifications);

    setNotifications((prev) => {
      const prevIds = prev.map(p => p.id);
      // console.log({ prevIds });
      const updatedList = [...prev];
      newNotifications.forEach((notification) => {
        if (!prevIds.includes(notification.id)) {
          // console.log('new id', { id: notification.id });
          updatedList.push(formatNotification(notification));
        }
      });
      return updatedList;
    });
  }, [notificationsState.data]);
  
  const onLoadMore = () => {
    notificationsState.setCurrentPage((current: number) => current + 1);
  };

  const loadMore = !(notificationsState.loading || notifications.length >= notificationsState.total) ? (
    <Button style={{ marginTop: 8 }} onClick={onLoadMore} block>{t('g.load_more')}</Button>
  ) : null; 

  const onNotificationEvent = async (event: INotification) => {
    // console.log('notification event', { event });
    // prepend to current notifications list
    setNotificationCount(prev => prev + 1);
    setNotifications(prev => ([formatNotification(event), ...prev]));
  };

  useEffect(() => {
    const ordersSocketListeners = socket.service('notifications').listeners('created');

    if (ordersSocketListeners.length === 0) {
      socket.service('notifications').on('created', onNotificationEvent);
    }

    return () => {
      socket.removeAllListeners();
    };
  }, []);

  return (
    <Row
      align="middle"
      justify="space-between"
      className="topbar__content"
    >
      <Drawer
        open={myPersonalRackModal.visible}
        width="70%"
        title={t('g.move_products_in_inventory')}
        placement={isScreenMobile() ? 'bottom' : 'right'}
        height="90%"
        onClose={() => { myPersonalRackModal.hide(); }}
        bodyStyle={{ padding: isScreenMobile() ? 5 : undefined }}
        destroyOnClose
      >
        <MyPersonalRackPicking />
      </Drawer>
      <Col span={isBreakpointEqualsAndBelow('sm') ? '24' : undefined}>
        <Space>
          <Button
            type="text"
            size="large"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={onClickCollapsed}
          />
          <Link className="topbar__logo" to="/" />
        </Space>
      </Col>
      <Col span={isBreakpointEqualsAndBelow('sm') ? '24' : undefined}>
        <Space
          size="large"
          direction={isBreakpointEqualsAndBelow('sm') ? 'vertical' : 'horizontal'}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<SwapOutlined className="align-icon" />}
            onClick={() => (myPersonalRackModal.show())}
          />
          <Cascader
            value={warehouseContextValues}
            options={warehouseContextOptions}
            onChange={handleWarehouseContextChange}
            showCheckedStrategy={Cascader.SHOW_CHILD}
            notFoundContent={t('g.results_not_found')}
            showSearch
            multiple
            dropdownRender={(menus: React.ReactNode) => (
              <Space direction="vertical">
                {menus}
                <Divider style={{ margin: 0 }} />
                <Button type="link" onClick={selectAll}>{t('g.select_all')}</Button>
                <Button type="link" onClick={deselectAll}>{t('g.deselect_all')}</Button>
              </Space>
            )}
          >
            <Typography.Text>
              {warehouseContexText} <Typography.Link>{t('g.change')}</Typography.Link>
            </Typography.Text>
          </Cascader>
          <Popover
            trigger="click"
            title={t('g.notifications')}
            // content={NotificationList}
            content={(
              <NotificationsList
                loading={notificationsState.loading}
                loadMore={loadMore}
                dataSource={notifications}
                renderItem={(item, index) => (
                  <NotificationListItem {...item} />
                )}
              />
            )}
            onOpenChange={(visible) => {
              // console.log({ visible });
              if (visible) {
                setNotificationCount(0);
              }
            }}
          >
            <Badge count={notificationCount}>
              <Button
                type="text"
                icon={<BellOutlined className="align-icon" />}
              />
            </Badge>
          </Popover>
          <TopbarProfile />
        </Space>
      </Col>
    </Row>
  );
};

export default Topbar;
