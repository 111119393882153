enum PickingJourneySteps {
    Rack = 'rack',
    Product = 'product',
    Config = 'config',
    ReferenceQty = 'referenceQty',
    ProductQty = 'productQty',
    FoliosQty = 'foliosQty',
    FoliosList = 'foliosList'
}

export default PickingJourneySteps;
