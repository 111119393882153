import React from 'react';

const MainWrapper = ({ children }) => (
  <div className="theme-light ltr-support">
    <div className="wrapper">
      {children}
    </div>
  </div>
);

export default MainWrapper;
