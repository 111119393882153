import React, { ComponentProps, CSSProperties } from 'react';
import { 
  Table, Image, Spin, Space,
} from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { ReactElementLike } from 'prop-types';
import { ExpandableConfig, TableRowSelection } from 'antd/lib/table/interface';
import { UsePaginationService } from '@/hooks/usePaginationService';
import DebounceSearch from '../../form/DebounceSearch';

const customLoader = process.env.REACT_APP_LOADER;
if (customLoader) {
  Spin.setDefaultIndicator(<Image width={30} src={customLoader} />);
  Spin.defaultProps = { size: 'large' };
}

interface SearchableTableProps {
  data: any;
  columns: (ColumnGroupType<any> | ColumnType<any>)[];
  pagination?: UsePaginationService<any>;
  placeholder?: string;
  disableSearch?: boolean;
  onSearch?: (value: any) => void;
  onChange?: (value: any) => void;
  children?: ReactElementLike;
  subhead?: string;
  loading?: boolean;
  rowKey?: string;
  expandable?: ExpandableConfig<any>;
  rowSelection?: TableRowSelection<any>;
  onRow?: ComponentProps<any>;
  style?: CSSProperties;
}

const SearchableTable = ({
  pagination,
  data,
  columns,
  placeholder,
  disableSearch,
  onSearch,
  onChange,
  subhead,
  loading,
  children,
  expandable,
  rowSelection,
  rowKey = 'id',
  onRow,
  style,
}: SearchableTableProps) => (
  <>
    {
      (subhead || children) ? (
        <div className="react-table__wrapper">
          {subhead && <p className="subhead">{subhead}</p>}
          <Space>
            {children}
          </Space>
        </div>
      ) : null
    }
    {
      !disableSearch ? (
        <DebounceSearch 
          placeholder={placeholder}
          allowClear
          onSearch={onSearch || (() => { })}
          onChange={onChange}
          style={{ marginBottom: 12 }}
        />
      ) : null
    }
    <Table
      pagination={pagination?.pagination || false}
      style={{ overflowX: 'auto', ...style }}
      loading={loading}
      rowKey={rowKey}
      onChange={(paginationChange: any) => {
        pagination?.setCurrentPageSize(paginationChange.pageSize);
        pagination?.setCurrentPage(paginationChange.current);
      }}
      dataSource={data}
      columns={columns}
      footer={() => <div style={{ height: '1px' }} />}
      expandable={expandable}
      rowSelection={rowSelection}
      onRow={onRow}
    />
  </>
);

export default SearchableTable;
