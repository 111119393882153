/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Button, Tag } from 'antd';
import { ExpandableConfig } from 'antd/lib/table/interface';
import Container from '@/shared/components/Container';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import DotsDropdown from '@/shared/components/DotsDropdown';
import RackInventoryProduct from '@/interfaces/RackInventoryProduct';
import DropdownOption from '@/interfaces/DropdownOption';
import MetaProp from '@/interfaces/MetaProps';
import IInventoryProductDetail from '@/interfaces/IInventoryProductDetail';
import useService from '@/hooks/useService';
import ActionButton from '@/interfaces/ActionButton';
import SearchableTable from '../table/components/SearchableTable';

interface Props {
  service: (
    params?: any,
  ) => Promise<IInventoryProductDetail[]>;
  params?: MetaProp,
  subhead?: string | undefined,
  children?: any,
  expandable?: ExpandableConfig<any>;
  actions?: ((prod: RackInventoryProduct) => DropdownOption[]);
  actionBtns?: ((prod : IInventoryProductDetail, params: any) => ActionButton[]);
  reloadService?: boolean;
}

const InventoryProductDetailsList = ({
  subhead,
  children,
  expandable,
  actions,
  actionBtns,
  service,
  reloadService,
  params,
}: Props) => {
  const { t } = useCustomTranslation();
  // const lang = i18next.language;

  const config:{
    skip?: number,
  pageSize?: number,
  params?: MetaProp
  } = {};

  if (params) {
    config.params = params;
  }

  const dataState = useService<any>(
    {
       ...config,
      fetchData: service,
    },
  );

  useEffect(() => {
    dataState.reload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadService]);

  const columns = [
    {
      title: t('g.quantity'),
      dataIndex: ['qty'],
    },
    /* {
      title: t('g.lpn'),
      dataIndex: ['lpnId'],
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: t('g.exp_date'),
      dataIndex: 'expDate',
      render: (value: any) => dayjs(value).local().format('DD/MM/YYYY  HH:mm:ss'),
    }, */
    ...actionBtns ? [
      {
          title: t('g.actions'),
          render: (inventoryProductDetail: IInventoryProductDetail) => {
            const actionButtons = actionBtns(inventoryProductDetail, params).map((actionButton: ActionButton) => (
              <Button
                type="primary"
                shape="circle"
                icon={actionButton.icon}
                onClick={() => (actionButton.func())}
              />
            ));
            return actionButtons;
          },
      },
    ] : [],
    ...actions ? [
        {
            title: t('g.actions'),
            dataIndex: 'id',
            width: 30,
            render: (text: string, value: any) => {
                const dropdownMenu = actions(value);

                return (
                  <div className="panel__btns" style={{ top: 20 }}>
                    <DotsDropdown dropdownMenu={dropdownMenu} />
                  </div>
                );
            },
        },
    ] : [],
];

  return (
    <Container>
      <SearchableTable
        subhead={subhead}
        columns={columns}
        data={dataState.data}
        loading={dataState.loading}
        // pagination={pagination}
        disableSearch
        rowKey="lpnId"
        expandable={expandable}
      >
        {children}
      </SearchableTable>
    </Container>
  );
};


export default InventoryProductDetailsList;
