import dayjs from 'dayjs';
import { FolioTag } from '@/interfaces/Folio';
import Optional from '@/types/Optional';

export default function decodeFolioTag(encodedTag: string): Optional<FolioTag> {
    const [id, prodId, expDateUnix, sku] = encodedTag.split('::');

    const decodeHypens = (str: string): string => str.replace(/\./g, '-');

    const hasAllParameters = id && prodId && expDateUnix && sku;
    if (!hasAllParameters) return null;

    return { 
        id: decodeHypens(id),
        prodId: +prodId,
        sku: decodeHypens(sku),
        expDate: dayjs.unix(+expDateUnix),
    };
}
