import React, { useState } from 'react';
import {
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
  TagFilled,
} from '@ant-design/icons';
import {
  Button, Col, Skeleton, Form, Row, Slider, 
} from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import useCustomTranslation from '@/hooks/useCustomTranslation';

import Rack from '@/interfaces/Rack';
import { Product } from '@/interfaces/ProductRefactor';

import QtyInput from '@/containers/Views/EntryPlan/components/QtyInput';
import DestinationInfo from '@/containers/Views/EntryPlan/components/DestinationInfo';
import ProductCardInfo from '@/containers/Views/EntryPlan/components/ProductCardInfo';

import Optional from '@/types/Optional';
import formatQty from '@/shared/helpers/formatQty';

interface PickupJourneyQuantityReferenceProps {
  product: Optional<Pick<Product, 'nameEn' | 'nameEs' | 'productImgs' | 'sku' | 'unit' | 'restrictions' >>;
  rack: Optional<Rack>;
  quantity: number;
  stepper?: number;
  precision?: number;
  text: string;
  qtyFeedback?: string;
  referenceQty?: number;
  disableNext?: boolean,
  disableBack?: boolean,
  loading: boolean,
  onNext?: () => void;
  onBack?: () => void;
  hideRange?: boolean;
  onIncrease?: (value: number) => void;
  onDecrease?: (value: number) => void;
  onChange?: (value: number) => void;
}

const PickupJourneyQuantityReference = ({
  quantity, 
  product, 
  rack, 
  stepper = 1,
  precision = 0,
  referenceQty = 0, 
  qtyFeedback = '',
  text, 
  disableNext = false,
  disableBack = false,
  hideRange = false,
  loading,
  onNext,
  onBack,
  onIncrease = () => {},
  onDecrease = () => {},
  onChange = () => {},
}: PickupJourneyQuantityReferenceProps) => {
  const { t } = useCustomTranslation();
  const [qtyForm] = Form.useForm();

  const warningMinTreshold = product?.restrictions?.minHoldVariation?.warning
    ? Math.round(referenceQty - (product?.restrictions?.minHoldVariation?.warning * referenceQty))
    : 0;

  const warningMaxTreshold = product?.restrictions?.maxHoldVariation?.warning
    ? Math.round(referenceQty + (product?.restrictions?.maxHoldVariation?.warning * referenceQty))
    : 0;

  const limitMinTreshold = product?.restrictions?.minHoldVariation?.limit
    ? Math.round(referenceQty - (product?.restrictions?.minHoldVariation?.limit * referenceQty))
    : 0;

  const limitMaxTreshold = product?.restrictions?.maxHoldVariation?.limit
    ? Math.round(referenceQty + (product?.restrictions?.maxHoldVariation?.limit * referenceQty))
    : referenceQty;

  const maxSliderValue = (limitMaxTreshold !== 0 && limitMaxTreshold !== referenceQty)
    ? (limitMaxTreshold + (referenceQty * 0.1)) : referenceQty;
  const minSliderValue = limitMinTreshold ? limitMinTreshold - (referenceQty * 0.1) : 0;

  const onDecreaseStep = (stepperValue: number, multiplierValue: number) => {
    const value = formatQty({
      qty: stepperValue * multiplierValue,
      decimalPoints: precision,
    });

    onDecrease(value);
  };

  const onIncreaseStep = (stepperValue: number, multiplierValue: number) => {
    const value = formatQty({
      qty: stepperValue * multiplierValue,
      decimalPoints: precision,
    });

    onIncrease(value);
  };

  const onChangeStep = (stepperValue: number, multiplierValue: number) => {
    const value = formatQty({
      qty: stepperValue * multiplierValue,
      decimalPoints: precision,
    });

    onChange(value);
  };

  const marks: SliderMarks = {
    [`${referenceQty}`]: {
      style: {
        color: '#42adf5',
        fontSize: 18,
      },
      label: referenceQty,
    },
    [`${limitMinTreshold}`]: {
      style: {
        color: '#c92a06',
        fontSize: 16,
      },
      label: limitMinTreshold,
    },
    [`${limitMaxTreshold}`]: {
      style: {
        color: '#c92a06',
        fontSize: 16,
      },
      label: limitMaxTreshold,
    },
    [`${warningMinTreshold}`]: {
      style: {
        color: '#f5db31',
        fontSize: 16,
      },
      label: warningMinTreshold,
    },
    [`${warningMaxTreshold}`]: {
      style: {
        color: '#f5db31',
        fontSize: 16,
      },
      label: warningMaxTreshold,
    },

  };

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <DestinationInfo
          centerIcon={<ArrowRightOutlined />}
          to={<p>{rack?.alias ?? t('g.rack')}</p>}
        />
      </Col>
      <Col span={24} style={{ padding: '0px 50px' }}>
        {product ? (
          <ProductCardInfo
            product={product}
            qty={qtyFeedback}
          />
        ) : (
          <Skeleton />
        )}
      </Col>
      <Col>
        <QtyInput
          form={qtyForm}
          value={quantity}
          onChange={onChangeStep}
          onDecrease={onDecreaseStep}
          onIncrease={onIncreaseStep}
          stepper={stepper}
          precision={precision}
        />
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <span style={{ fontSize: 28 }}>{product?.unit.code}</span>
      </Col>
      {!hideRange && (
        <Col span={24} style={{ width: '100%', padding: '0px 50px' }}>
          <Slider
            style={{ width: '100%' }}
            value={quantity}
            marks={marks}
            step={1}
            min={minSliderValue}
            max={maxSliderValue}
          />
        </Col>
      )}
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            {onBack && (
              <Button
                type="text"
                icon={<LeftOutlined />}
                onClick={onBack}
                disabled={disableBack}
                loading={loading}
              >{t('g.back')}
              </Button>
            )}
          </Col>
          <Col>
            {onNext && (
              <Button
                type="primary"
                icon={<RightOutlined />}
                onClick={onNext}
                disabled={disableNext}
                loading={loading}
              >{text ?? t('g.next')}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PickupJourneyQuantityReference;
