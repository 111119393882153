import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const MasterFranchiseDetails = lazy(
  () => import('@/containers/Views/MasterFranchise/MasterFranchiseDetails/index'),
);
const MasterFranchiseNewForm = lazy(
  () => import('@/containers/Views/MasterFranchise/MasterFranchiseForm/masterFranchiseNew'),
);
const MasterFranchiseEditForm = lazy(
  () => import('@/containers/Views/MasterFranchise/MasterFranchiseForm/masterFranchiseEdit'),
);
const MasterFranchiseStaffDetailPage = lazy(
  () => import('@/containers/Views/Staff/MasterFranchiseStaffDetailPage'),
);
const FranchiseNew = lazy(
  () => import('@/containers/Views/Franchise/FranchiseForm/franchiseNew'),
);

const MasterFranchiseRouter = (
  <Route path="master_franchises">
    <Route
      path="new" 
      element={(
        <PrivateRoute 
          pageName={EPage.MasterFranchises} 
          permissions={EBasicActions.Create}
        >
          <MasterFranchiseNewForm />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.MasterFranchises} 
          permissions={EBasicActions.Edit}
        >
          <MasterFranchiseEditForm />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id"
      element={(
        <PrivateRoute 
          pageName={EPage.MasterFranchises} 
          permissions={EBasicActions.Get}
        >
          <MasterFranchiseDetails />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/franchises/new"
      element={(
        <PrivateRoute 
          pageName={EPage.Franchises} 
          permissions={EBasicActions.Create}
        >
          <FranchiseNew />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/staff/config/:staffId"
      element={(
        <PrivateRoute 
          pageName={EPage.Staff} 
          permissions={[
            EBasicActions.Get,
            EBasicActions.Edit,
          ]}
        >
          <MasterFranchiseStaffDetailPage />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default MasterFranchiseRouter;
