/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  CheckOutlined,
  EnvironmentFilled, LeftOutlined, QrcodeOutlined, TagFilled,
} from '@ant-design/icons';
import {
  Button, Col, Form, FormInstance,
  Input, InputRef, Progress, Row, Tag, Typography,
} from 'antd';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import i18next from 'i18next';
import Rack from '@/interfaces/Rack';
import { Product } from '@/interfaces/ProductRefactor';

interface IPickupJourneyFoliosProgressProps {
  product: Product | null | undefined;
  loading: boolean;
  rack: Rack | null | undefined;
  folios: string[];
  onScan: (value: string) => Promise<void>;
  onSubmit?: () => void;
  onBack?: () => void;
  maxFolios: number;
  ref: React.RefObject<InputRef>;
  form: FormInstance<any>;
}

const PickupJourneyFoliosProgress = ({
  ref,
  rack, 
  folios,
  loading,
  product,
  maxFolios, 
  form,
  onScan,
  onBack,
  onSubmit,
}: IPickupJourneyFoliosProgressProps) => {
  const { t } = useCustomTranslation();

  const [percent, setPercent] = useState<number>(0);

  const INIT_VALUES = {
    folio: '',
  };

  useEffect(() => {
    setPercent((100 * folios.length) / maxFolios);
  }, [folios.length]);

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.focus();
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={INIT_VALUES}
    >
      <Row 
        gutter={[16, 16]}
      >
        <Col span={24}>
          <Row
            gutter={8}
            justify="center" 
            align="middle"
          >
            <Col>
              <EnvironmentFilled style={{ fontSize: '180%' }} />
            </Col>
            <Col>
              <Typography.Title level={5}>{rack?.alias}</Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            gutter={8}
            justify="center" 
            align="middle"
          >
            <Col>
              <TagFilled style={{ fontSize: '180%' }} />
            </Col>
            <Col>
              <Typography.Text>
                <Tag>{product?.sku}</Tag>
                {i18next.language === 'en' ? product?.nameEn : product?.nameEs}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            gutter={8}
            justify="center" 
            align="middle"
          >
            <Col>
              <Progress
                type="circle"
                percent={percent}
                format={percent === 100 ? undefined : () => `${folios.length}/${maxFolios}`}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item name="folio">
            <Input
              ref={ref}
              disabled={percent === 100 || maxFolios === 0}
              size="large"
              inputMode="none"
              addonBefore={<QrcodeOutlined />}
              placeholder={t('g.folios')}
              onPressEnter={(e: any) => {
                onScan(e.target.value).then(() => {
                  form.resetFields();
                  ref?.current?.focus();
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              {onBack && (
                <Button
                  type="text"
                  icon={<LeftOutlined />}
                  onClick={onBack}
                  loading={loading}
                >{t('g.back')}
                </Button>
              )}
            </Col>
            <Col>
              {onSubmit && (
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={onSubmit}
                  disabled={percent !== 100}
                  loading={loading}
                >
                  {t('g.finish')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default PickupJourneyFoliosProgress;
