import React from 'react';
import { Tag } from 'antd';
import useCustomTranslation from '@/hooks/useCustomTranslation';

interface ActiveTagProps {
    isActive: boolean | number;
}
 
const ActiveTag = ({ isActive }:ActiveTagProps) => {
    const { t } = useCustomTranslation();

  
    let color = 'default';
    let message = '';
    if (isActive || isActive === 1) { color = 'green'; message = t('g.active'); }
    if (!isActive || isActive === 0) { color = 'default'; message = t('g.inactive'); }
  
    return (
      <Tag color={color}>{message}</Tag>
    );
  };
export default ActiveTag;
