import axiosApiConfig from '@/axiosApiConfig';
import { PaginationResponse } from '@/hooks/usePaginationService';
import Folio from '@/interfaces/Folio';
import Optional from '@/types/Optional';

const getFolios = async (params?: any): Promise<Folio[]> => {
  const result : any = await axiosApiConfig.get('/folios', { params: { ...params } });
  return result.data.data;
};

export const getFolio = async (id: Folio['id']): Promise<Optional<Folio>> => {
  const result = await axiosApiConfig.get(`/folios/${id}`)
    .catch((err) => {
      console.error(err);
      // TODO: manage when API does not work, is not NULL is an Error -> throw error
      return null;
    });
  return result?.data || null;
};

export const createFolios = async (data: any[]): Promise<Folio> => {
  const result = await axiosApiConfig.post<any>('/folios/', data);
  return result.data;
};

export const editFolio = async (data: Folio): Promise<Folio> => {
    const result = await axiosApiConfig.patch<any>(`/folios/${data.id}`, data);
    return result.data;
};

export async function getFoliosPaginated(
    skip: number, 
    limit: number, 
    params?: any,
): Promise<PaginationResponse<Folio>> {
    const result : any = await axiosApiConfig.get('/folios', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });
    return result.data;
}

// ! what is what this function is looking for? fetching folios? the name 
// ! sayis there is something todo with the packages
export const findFolioInPackages = async (data: {folios:Folio['id'][]}): Promise<Folio[]> => {
  const result : any = await axiosApiConfig.post('/find_folio_in_packages', data);
  return result.data;
};

export default getFolios;
