/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// import 'antd/dist/antd.css';


import Icon, {
  LeftOutlined,
 QrcodeOutlined, RightOutlined, TagFilled,
} from '@ant-design/icons';
import {
  Button, Col, Form, Input, Row, Segmented,
} from 'antd';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import i18next from 'i18next';
import useAuth from '@/hooks/useAuth';
import DebounceSelect from '@/shared/components/form/DebounceSelect';
import { getProducts } from '@/services/Product';
import { Product } from '@/interfaces/ProductRefactor';
import PickingTypes from '@/enums/PickingTypes';
import PickingJourneySearchTypes from '@/enums/PickingJourneySearchTypes';
import { FormInstance } from 'antd/lib/form';
import { SegmentedValue } from 'antd/es/segmented';
import Optional from '@/types/Optional';

export interface PickupJourneyConfigForm {
  pickingType: PickingTypes,
  scannerInput: string,
  sku: string,
}

interface PickupJourneyConfigProps {
  // product: Product | null | undefined;
  // setProduct: React.Dispatch<React.SetStateAction<Product | null | undefined>>;
  form: FormInstance<PickupJourneyConfigForm>,
  initValues?: Partial<PickupJourneyConfigForm>,
  disableNext?: boolean,
  disableBack?: boolean,
  onNext?: () => void;
  onBack?: () => void;
  fetchProducts: (value: string) => Promise<Product[]>;
  onPressEnter: (value?: string) => Promise<void>;
  onSelect: (value: string) => void,
  onDeselect: () => void,
  onPickingTypeChange?: (value: PickingTypes) => void;

  // onSearchProduct: any;
  // pickingType: PickingTypes;
  // setPickingType:(newPickingType: PickingTypes) => void;
  // searchType: PickingJourneySearchTypes;
  // setSearchType: React.Dispatch<React.SetStateAction<"folio" | "lpn" | "sku" | any>>;
  qrRef: React.MutableRefObject<any>;
}

const PickupJourneyConfig = ({
  // product, 
  form,
  initValues,
  // setProduct, 
  disableNext = false,
  disableBack = false,
  onNext, 
  onBack, 
  fetchProducts,
  onPressEnter,
  onPickingTypeChange = (v: string) => {}, 
  // onSearchProduct, 
  qrRef,
  onSelect,
  onDeselect,
  // pickingType, 
  // searchType, 
  // setPickingType, 
  // setSearchType,
}: PickupJourneyConfigProps) => {
  const { t } = useCustomTranslation();

  const requiredRule = {
    required: true,
    message: t('validation.required'),
  };

  useEffect(() => {
    if (qrRef?.current) {
      qrRef?.current?.focus();
    }
    // if (product) {
    //   form.setFieldsValue({ productId: product.id });
    // }
    // if (searchType) {
    //   form.setFieldsValue({ searchType });
    // }
    // if (pickingType) {
    //   form.setFieldsValue({ pickingType });
    // }
  }, []);

  // useEffect(() => {
  //   if (searchType === PickingJourneySearchTypes.Lpn) {
  //     form.setFieldsValue({ pickingType: PickingTypes.Simple });
  //   }
  // }, [searchType]);

  // const productsInitialState = useService<Product>(
  //   {
  //     params: { id: product?.id },
  //     fetchData: getProducts,
  //   },
  // );
  const INIT_VALUES: PickupJourneyConfigForm = {
    pickingType: PickingTypes.Simple,
    scannerInput: '',
    sku: '',
    ...initValues,
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={INIT_VALUES}
    >
      <Col>
        <Row justify="center">
          <TagFilled style={{ fontSize: '500%', marginBottom: 20 }} />
        </Row>
        <Row justify="center">
          <h5>{t('g.select_your_picking_type')}</h5>
        </Row>
        <Row justify="center" style={{ marginBottom: 16 }}>

          <Form.Item name="pickingType" rules={[requiredRule]}>
            <Segmented
              // value={pickingType}
              // disabled={searchType === PickingJourneySearchTypes.Lpn}
              onChange={(value: SegmentedValue) => onPickingTypeChange(value as PickingTypes)}
              size="large"
              options={[
                {
                  label: t('g.simple'),
                  value: PickingTypes.Simple,
                  icon: <Icon type="pushpin" />,
                },
                {
                  label: t('g.advanced'),
                  value: PickingTypes.Advanced,
                  icon: <Icon type="tool" />,
                },
              ]}
            />
          </Form.Item>
        </Row>

        <Row justify="center">
          <h5>{t('g.select_your_picking_type')}</h5>
        </Row>

        <Row justify="center" style={{ marginBottom: 16 }}>
          <Form.Item name="scannerInput" style={{ width: '100%' }}>
            <Input
              ref={qrRef}
              size="large"
              inputMode="none"
              addonBefore={<QrcodeOutlined />}
              onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
                const { value } = (event.target as HTMLInputElement); // folio or sku
                onPressEnter(value);
              }}
              placeholder="folio | sku"
            />
          </Form.Item>
        </Row>
        <Row justify="center" style={{ marginBottom: 20 }}>
          <h5>{t('g.find_a_product')}</h5>
          <Form.Item name="sku" style={{ width: '100%' }}>
            <DebounceSelect
              style={{ width: '100%' }}
              fetchOptions={fetchProducts}
              refactFunc={(products: Product[]) => products.map(item => ({
                value: item.sku,
                label: i18next.language === 'en' ? item.nameEn : item.nameEs,
                tag: item.sku,
              }))}
              onSelect={onSelect}
              onClear={onDeselect}
              
              // onSelect={e => setProduct(productsState.find(i => i.id === e))}
              // onDeselect={() => setProduct(undefined)}
              //   initialOptions={product ? [{
              //     value: product.id,
              //     label: i18next.language === 'en' ? product.nameEn : product.nameEs,
              //     tag: product.sku,
              // }] : undefined}
            />
          </Form.Item>
        </Row>
        {/* {(searchType === PickingJourneySearchTypes.Sku 
        || (searchType === PickingJourneySearchTypes.Folio
           && pickingType === PickingTypes.Advanced)) 
          && (
        )} */}
        <Row justify="space-between">
          { onBack && (
            <Button
              type="text"
              icon={<LeftOutlined />}
              onClick={onBack}
              disabled={disableBack}
            >{t('g.back')}
            </Button>
          )}
          { onNext && (
            <Button
              type="primary"
              icon={<RightOutlined />}
              onClick={onNext}
              disabled={disableNext}
            >{t('g.next')}
            </Button>
          )}
        </Row>
      </Col>
    </Form>
  );
};

export default PickupJourneyConfig;
