import {
    Col, Row, Tag, Typography,
} from 'antd';
import React, { ReactNode } from 'react';

interface CardInfoProps {
    img?: ReactNode;
    label: string;
    subLabel?: string;
    tags: string[];
    extra?: ReactNode;
}

const CardInfo = ({
  label,
  tags,
  img = undefined,
  subLabel = undefined,
  extra,
}: CardInfoProps) => (
  <Row gutter={[16, 16]}>
    { img !== undefined ? (
      <Col flex={0}>{img}</Col>
    ) : null }
    <Col flex={1}>
      <Row justify="space-between">
        <Col>
          {tags.map(t => <Tag key={t}>{t}</Tag>)}
        </Col>
        { extra ? (
          <Col>
            {extra}
          </Col>
        ) : null }
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {label}
          </Typography.Title>
        </Col>
        { subLabel !== undefined ? (
          <Col span={24}>
            <Typography.Title level={4} style={{ fontWeight: 400, marginBottom: 0 }}>
              {subLabel}
            </Typography.Title>
          </Col>
        ) : null }
      </Row>

    </Col>
  </Row>
);

export default CardInfo;
