import React from 'react';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import {
  List,
  ListProps,
} from 'antd';

interface INotificationsList<T> {
  loading?: ListProps<T>['loading'];
  loadMore?: ListProps<T>['loadMore'];
  renderItem?: ListProps<T>['renderItem'];
  dataSource: ListProps<T>['dataSource'];
}

const NotificationsList = <T, >({
  renderItem,
  loading,
  loadMore,
  dataSource,
}: INotificationsList<T>) => {
  const { t } = useCustomTranslation();
  return (
    <List
      loading={loading}
      loadMore={loadMore}
      className="list"
      style={{
        width: 280,
        height: 400,
        overflowY: 'scroll',
      }}
      dataSource={dataSource}
      renderItem={renderItem}
    />
  );
};

export default NotificationsList;
