import React from 'react';
import { 
  Avatar, Button, Col, Dropdown, MenuProps, Row, Space, 
} from 'antd';
import useAuth from '@/hooks/useAuth';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';

const TopbarProfile = () => {
  const auth = useAuth();

  const items: MenuProps['items'] = [
    {
      label: 'Cerrar sesión',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: () => auth?.logout(),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Dropdown 
          menu={{ items }} 
          placement="bottomRight"
          arrow
          trigger={['click']}
        >
          <Button 
            type="link"
            icon={(
              <Avatar 
                alt="user avatar"
                src={auth?.staff?.photoUrl} 
              />
            )}
            style={{ 
              color: '#212529', 
              padding: 0,
            }}
          >
            <Space align="center">
              {auth?.staff?.name}
              <DownOutlined style={{ marginBottom: '6px' }} />
            </Space>
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default TopbarProfile;
