interface IFormatQty {
  qty: number;
  decimalPoints?: number;
}

const formatQty = ({
  qty,
  decimalPoints = 1,
}: IFormatQty) => Number(qty.toFixed(decimalPoints));

export default formatQty;
