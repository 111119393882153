import axiosApiConfig from '@/axiosApiConfig';
import { PaginationResponse } from '@/hooks/usePaginationService';
import INotification from '@/interfaces/INotification';

export async function getNotificationsPaginated(
  skip: number, 
  limit: number, 
  params?: any,
): Promise<PaginationResponse<INotification>> {
  const result = await axiosApiConfig.get<PaginationResponse<INotification>>('/notifications', { 
    params: { 
      ...params,
      $skip: skip,
      $limit: limit,
    },
  });

  // console.log({ ...result.data });
  return {
    total: result.data.total,
    skip: result.data.skip,
    limit: result.data.limit,
    data: result.data.data,
  };
}

export default { getNotificationsPaginated };
