import EBasicActions from '@/enums/EBasicActions';
import EPages from '@/enums/EPages';
import useAuth from '@/hooks/useAuth';
import IActionsToValidate from '@/interfaces/IActionsToValidate';
import IAllowedActions from '@/types/IAllowedActions';


function usePermissions() {
  const auth = useAuth();

  const isValidAction = (action: EBasicActions, page: EPages): boolean => {
    if (!auth || !auth?.actions) return false;

    if (!auth.actions[page]) return false;

    const isValid = auth.actions[page].includes(action);

    return isValid;
  };

  function getAllowedActions(actionsList: IActionsToValidate[]): IAllowedActions {
    const allowedActions: IAllowedActions = {};
    
    for (let i = 0; i < actionsList.length; i += 1) {
      const { page, actions } = actionsList[i];

      // init 
      for (let j = 0; j < actions.length; j += 1) {
        const action = actions[j];
        if (isValidAction(action, page)) {
          if (!(page in allowedActions)) {
            allowedActions[page] = {};
          }

          allowedActions[page] = {
            ...allowedActions[page],
            [action]: true,
          };
        }
      }
    }

    return allowedActions;
  }

  return {
    isValidAction,
    getAllowedActions,
  };
}

export default usePermissions;
